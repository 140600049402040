import "./App.css";

import { useEffect, useState } from "react";

import Header from "./Components/Header/Header";
import TelaLogin from "./Components/TelaLogin/TelaLogin";
import AboutButton from "./Components/AboutButton/AboutButton";
import { ContextMapProvider } from "./ContextMap/ContextMap";
import Conteudo from "./Components/Conteudo/Conteudo";

import { ToastProvider } from "./Components/common";

function App() {
  const [openTelaLogin, setOpenTelaLogin] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [estadoLogin, setEstadoLogin] = useState("");

  function toggletelaLogin() {
    setOpenTelaLogin(!openTelaLogin);
  }

  function toggletelaSidebar() {
    setShowSidebar(!showSidebar);
  }

  function nullEstadoLogin() {
    sessionStorage.setItem("infoUsuarioToken", "");
    setEstadoLogin("");
  }

  function toggleteEstadoLogin() {
    const token = sessionStorage.getItem("infoUsuarioToken");
    setEstadoLogin(token);
  }

  useEffect(() => {
    toggleteEstadoLogin();
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("infoUsuarioToken");
    if (token) {
      setOpenTelaLogin(false); // Usuário está logado
    } else {
      setOpenTelaLogin(true); // Usuário não está logado
    }
  }, [estadoLogin]);

  return (
    <div className='App'>
      <ContextMapProvider>
        <ToastProvider>
          <Header
            setOpenTelaLogin={toggletelaLogin}
            toggletelaSidebar={toggletelaSidebar}
            estadoLogin={estadoLogin}
            nullEstadoLogin={nullEstadoLogin}
          />
          {!openTelaLogin && (
            <div
              style={{
                marginLeft: !showSidebar && estadoLogin ? "250px" : "0px",
                transition: "margin-left 1s ease",
              }}
            >
              <Conteudo estadoLogin={estadoLogin} />
            </div>
          )}
          {/* {!openTelaLogin && !estadoLogin && (
            <div className='container-AboutButton'>
              <AboutButton />
            </div>
          )} */}
          <TelaLogin
            isOpen={openTelaLogin}
            setOpenTelaLogin={toggletelaLogin}
            toggleteEstadoLogin={toggleteEstadoLogin}
          />
        </ToastProvider>
      </ContextMapProvider>
    </div>
  );
}

export default App;
