import styled from "styled-components";

import Box from "@mui/material/Box";

export const Container = styled(Box).attrs({
  sx: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
})``;
