import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import "./GerenciarColetas.css";
import MapaColetas from "./components/Mapa/MapaColetas.jsx";
import { Api } from "../../services/Api";

const GerenciarColetas = () => {
  const [dadosMarchaUrbana, setDadosMarchaUrbana] = useState(null);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    const buscarDadosMarchaUrbana = async () => {
      try {
        const resposta = await Api.getMarchaUrbana();
        setDadosMarchaUrbana(resposta);
        setCarregando(false);
      } catch (erro) {
        console.error("Erro ao buscar dados da Marcha Urbana:", erro);
        setCarregando(false);
      }
    };

    buscarDadosMarchaUrbana();
  }, []);

  return (
    <div style={{ height: '91vh' }}>
      <div style={{ height: '100%' }}>
        {carregando ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          dadosMarchaUrbana && <MapaColetas initMapa={dadosMarchaUrbana} />
        )}
      </div>
    </div>
  );
};

export default GerenciarColetas;
