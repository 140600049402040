import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

import Button from "@mui/material/Button";

import {
  Card,
  FormLabel,
  FormControlLabel,
  Switch,
  RadioGroup,
  Divider,
  Box,
  Radio,
  FormControl,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Fill, Stroke, Style } from "ol/style.js";
import { OSM, Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import proj4 from "proj4";

import { Api } from "../../../services/Api.js";

import "./ModalEndIncopativeis.css";

const ModalEndIncopativeis = ({ onClose, idAdversidade }) => {
  const [informacoesAdv, setInformacoesAdv] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueCodigo, setSelectedValueCodigo] = useState("");
  const [valueTabs, setValueTabs] = useState(0);
  const [valueRadioGroup, setValueRadioGroup] = useState("");

  const [isTabDisabled, setIsTabDisabled] = useState(true); //habilitar  e desabilidar o tab
  const [showInputsSemRegistro, setShowInputsSemRegistro] = useState(true); //habilitar  e desabilidar o tab

  useEffect(() => {
    const fetchData = async () => {
      console.log(idAdversidade);

      try {
        const data = await Api.getAdversidadesId(18068);
        // Remover elementos com nome nulo
        const informacoesApi = data.data.result;
        console.log(data);
        setInformacoesAdv(informacoesApi);
      } catch (error) {
        console.log("Deu erro:", error);
      }
    };
    setInformacoesAdv(informacoesAdv);

    fetchData();
  }, []);

  const handleSubmit = (e) => {
    console.log(idAdversidade);
  };

  const onCloseClick = () => {
    onClose();
  };

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  const [state, setState] = useState(false);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeTabs = (event, newValue) => {
    console.log(newValue);
    setValueTabs(newValue);
  };

  const handleInputsSemRegistro = () => {
    setShowInputsSemRegistro(!showInputsSemRegistro);
  };

  const handleChangeRadioGroup = (event) => {
    if (valueRadioGroup === event.target.value) {
      setValueRadioGroup("");
    } else setValueRadioGroup(event.target.value);
  };

  const mapRef = useRef(null);
  const [currentZoom, setCurrentZoom] = useState(5);

  //useState coordenadas
  const [coordenadas, setCoordenadas] = useState([-44.0524, -18.4833]);

  //Configura a lista de acordo com o tamanho da tela
  // useEffect(() => {
  //   if (window.innerHeight > 750) {
  //     const numberPerPage = 12;
  //     setRowsPerPage(numberPerPage)
  //   }
  // }, [])

  const styles = {
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
      fill: new Fill({
        color: "rgba(255, 255, 0, 0.1)",
      }),
    }),
  };

  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  const [coordenadasPoligono, setCoordenadasPoligono] = useState([
    [507511.096510162, 7568780.598330974],
    [507518.823996995, 7568788.760727163],
    [507523.718691678, 7568797.971094619],
    [507530.018919432, 7568793.229685036],
    [507528.599032377, 7568789.29975676],
    [507529.417528115, 7568786.891102029],
    [507525.102074188, 7568783.64620372],
    [507521.015141249, 7568778.592657473],
    [507512.897200136, 7568775.259393719],
    [507511.096510162, 7568780.598330974],
  ]);

  useEffect(
    () => () => {
      configurarCoordenadaCentral();
      configurarCoordenada(coordenadasPoligono);
    },
    []
  );

  const configurarCoordenada = (coordenadasPoligono) => {
    const utmProjection =
      "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
    const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligono(latLngCoordinatesArray);
  };

  const geojsonObject = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "EPSG:4326",
      },
    },
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [[coordenadasPoligono]],
        },
      },
    ],
  };

  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });

  const configurarCoordenadaCentral = () => {
    const utmProjection =
      "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
    const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

    const coordenadasLimpo = [507520.195861565, 7568784.270602539];
    const latLngCoordinates = proj4(
      utmProjection,
      latLngProjection,
      coordenadasLimpo
    );
    setCoordenadas(latLngCoordinates);
  };

  useEffect(() => {
    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: [coordenadas[0], coordenadas[1]],
        zoom: 19,
        projection: "EPSG:4326",
      }),
    });
    return () => {
      newMap.dispose();
    };
  }, [coordenadas, currentZoom]);

  return (
    <div className="ModalEndIncopativeis-modal" onClick={onCloseClick}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className="ModalEndIncopativeis-modal-content"
          onClick={onModalClick}
        >
          <div className="ModalEndIncopativeis-close-bnt">
            <Button aria-label="Fechar" onClick={onCloseClick}>
              <CloseIcon />
            </Button>
          </div>
          <div className="containers-end">
            {/* Coluna 1 */}
            <Card
              variant="outlined"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h5 className="titulos-end">
                ENDEREÇO INCOPATIVEL NO CADASTRO DA PREFEITURA
              </h5>

              <div style={{ margin: "10px" }}>
                <form onSubmit={handleSubmit}>
                  <Box
                   className='box-end'
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "45px",
                        marginLeft: "10px",
                        height: "25px",
                      }}
                    >
                      <h5 style={{ marginBottom: "10px" }}>Dados do Lote:</h5>
                    </div>

                    <form>
                      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <form onSubmit={handleSubmit}>
                          <div className="ModalEndIncopativeis-form-group">
                            <label
                              htmlFor="setor"
                              className="ModalEndIncopativeis-label"
                            >
                              Nº de inscrição reduzido:
                            </label>
                            <input
                              className="ModalEndIncopativeis-fixed"
                              id="Setor"
                              value={1}
                              onChange={(e) => setSelectedValue(e.target.value)}
                              required
                            />
                          </div>

                          <div className="ModalEndIncopativeis-form-row">
                            <div className="ModalEndIncopativeis-form-group">
                              <label
                                htmlFor="setor"
                                className="ModalEndIncopativeis-label"
                              >
                                Setor:
                              </label>
                              <input
                                className="ModalEndIncopativeis-fixed"
                                id="Setor"
                                value={1}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                                required
                              />
                            </div>

                            <div className="ModalEndIncopativeis-form-group">
                              <label
                                htmlFor="setor"
                                className="ModalEndIncopativeis-label"
                              >
                                Quadra:
                              </label>
                              <input
                                className="ModalEndIncopativeis-fixed"
                                id="Setor"
                                value={1}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                                required
                              />
                            </div>

                            <div className="ModalEndIncopativeis-form-group">
                              <label
                                htmlFor="quadra"
                                className="ModalEndIncopativeis-label"
                              >
                                Lote:
                              </label>
                              <input
                                className="ModalEndIncopativeis-fixed"
                                id="Quadra"
                                value={1}
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </form>

                        <div className="ModalEndIncopativeis-form-group">
                          <label
                            htmlFor="setor"
                            className="ModalEndIncopativeis-label"
                          >
                            Proprietário:
                          </label>
                          <input
                            className="ModalEndIncopativeis-fixed"
                            id="Setor"
                            value={1}
                            onChange={(e) => setSelectedValue(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </form>

                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                      <form onSubmit={handleSubmit}>
                        <div className="ModalEndIncopativeis-form-group">
                          <label
                            htmlFor="setor"
                            className="ModalEndIncopativeis-label"
                          >
                            CPF/CNPJ:
                          </label>
                          <input
                            className="ModalEndIncopativeis-fixed"
                            id="Setor"
                            value={1}
                            onChange={(e) => setSelectedValue(e.target.value)}
                            required
                          />
                        </div>

                        <div className="ModalEndIncopativeis-form-row">
                          <div className="ModalEndIncopativeis-form-group">
                            <label
                              htmlFor="setor"
                              className="ModalEndIncopativeis-label"
                            >
                              Bairro:
                            </label>
                            <input
                              className="ModalEndIncopativeis-fixed"
                              id="Setor"
                              value={1}
                              onChange={(e) => setSelectedValue(e.target.value)}
                              required
                            />
                          </div>

                          <div className="ModalEndIncopativeis-form-group">
                            <label
                              htmlFor="setor"
                              className="ModalEndIncopativeis-label"
                            >
                              Logradouro Atual:
                            </label>
                            <input
                              className="ModalEndIncopativeis-fixed"
                              id="Setor"
                              value={1}
                              onChange={(e) => setSelectedValue(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="ModalEndIncopativeis-form-row">
                          <div className="ModalEndIncopativeis-form-group">
                            <label
                              htmlFor="codigo"
                              className="ModalEndIncopativeis-label"
                            >
                              Número:
                            </label>
                            <input
                              className="ModalEndIncopativeis-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) =>
                                setSelectedValueCodigo(e.target.value)
                              }
                              required
                            />
                          </div>

                          <div className="ModalEndIncopativeis-form-group">
                            <label
                              htmlFor="selector"
                              className="ModalEndIncopativeis-label"
                            >
                              É lote encravado?
                            </label>
                            <select
                              className="ModalEndIncopativeis-select"
                              id="select"
                              // value={postesIluminacao}
                              // onChange={(e) => setPostesIluminacao(e.target.value)}
                              required
                            >
                              <option value="">Selecione:</option>
                              <option value="">111</option>
                              <option value="">122</option>
                              <option value="">132</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Box>
                </form>
              </div>
            </Card>

            {/* colunas 2 */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Card variant="outlined">
                <div style={{ width: "600px", height: "525px" }}>
                  {!valueTabs && (
                    <div>
                      <h5 className="titulos-end">
                        GEOMETRIAS IDENTIFICADA NA VETORIZAÇÃO:
                      </h5>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          bgcolor: "background.paper",
                          marginTop: "10px",
                          color: "text.secondary",
                          "& svg": { m: 0.9 },
                          "& hr": { mx: 0.9 },
                        }}
                      >
                        <FormControl>
                          <FormLabel
                            style={{ marginLeft: "10px" }}
                            id="demo-controlled-radio-buttons-group"
                          >
                            Selecione o novo logradouro para cadastro:
                          </FormLabel>
                          <RadioGroup
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginLeft: "5px",
                            }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={valueRadioGroup}
                            onClick={handleChangeRadioGroup}
                          >
                            <FormControlLabel
                              value="associarTodasUnidades"
                              control={<Radio />}
                              label="Testada Id: XXXXXX - Logradouro: RUA XXXXXX"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          bgcolor: "background.paper",
                          color: "text.secondary",
                          "& svg": { m: 0.9 },
                          "& hr": { mx: 0.9 },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <FormControlLabel
                            style={{ flex: 1, marginLeft: 5 }}
                            control={
                              <Switch
                                checked={state.antoine}
                                onChange={handleChange}
                                name="mostrarLotes"
                              />
                            }
                            label="Mostrar lotes"
                          />
                          <FormControlLabel
                            style={{ flex: 1, marginLeft: 5 }}
                            control={
                              <Switch
                                checked={state.antoine}
                                onChange={handleChange}
                                name="mostrarTestada"
                              />
                            }
                            label="Mostrar testadas"
                          />
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "fixed",
                          margin: 0.7,
                          marginLeft: 5,
                          zIndex: 1,
                          width: "fit-content",
                          border: (theme) =>
                            `2px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          bgcolor: "background.paper",
                          color: "text.secondary",
                          "& svg": { m: 0.5 },
                          "& hr": { mx: 0.5 },
                        }}
                      >
                        <div
                          style={{
                            background: "#e57373",
                            height: "20px",
                            width: "20px",
                            margin: "15px",
                          }}
                        ></div>
                        <h5 style={{ margin: "15px", marginLeft: "0px" }}>
                          Lote
                        </h5>
                        <Divider orientation="vertical" flexItem />

                        <div
                          style={{
                            background: "#90caf9",
                            height: "20px",
                            width: "20px",
                            margin: "15px",
                          }}
                        ></div>
                        <h5 style={{ margin: "15px", marginLeft: "0px" }}>
                          Lotes vizinhos
                        </h5>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: "1",
                          backgroundColor: "#AAD3DF",
                        }}
                      >
                        <div
                          ref={mapRef}
                          style={{ width: "100%", height: "550px" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </div>

          <div className="ModalEndIncopativeis-form-row">
            <Button style={{ width: "10%" }} variant="contained">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "0.5em" }}
              />
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEndIncopativeis;
