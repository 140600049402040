import React from "react";
import Fab from "@mui/material/Fab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSpinner,
  faPrint,
  faCheck,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import "./Buttons.css";

const Button = ({ icon, onClick, disabled, loading }) => (
  <Fab variant="extended" color="primary" onClick={onClick} disabled={disabled}>
    {loading ? (
      <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      <FontAwesomeIcon icon={icon} />
    )}
  </Fab>
);

const Buttons = ({
  loading,
  showPrint,
  showCheck,
  onSearch,
  onPrint,
  onCheck,
  onHelp,
}) => (
  <div className="buttons">
    <Button icon={faMagnifyingGlass} onClick={onSearch} loading={loading} />

    {showPrint && (
      <Button icon={faPrint} onClick={onPrint} disabled={loading} />
    )}
    {showCheck && (
      <Button icon={faCheck} onClick={onCheck} disabled={loading} />
    )}

    <Button icon={faQuestion} onClick={onHelp} disabled={loading} />
  </div>
);

export default Buttons;
