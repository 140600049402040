import { createContext, useEffect, useState } from "react";
import { Api } from "../../../../services/Api";

const InputContext = createContext();

const ContextProviderInput = ({ children }) => {
  const [numero, setNumero] = useState(0);
  const [continueEstado, setContinueEstado] = useState(false);
  const [selectValueLogradouro, setSelectValueLogradouro] = useState("");
  const [selectValueTipo, setSelectValueTipo] = useState("");
  const [valueRadioGroup, setValueRadioGroup] = useState("");
  const [valueRadioGroupTestada, setValueRadioGroupTestada] = useState("");
  const [numUnidades, setNumUnidades] = useState(0);
  const [numCodigo, setNumCodigo] = useState("");
  const [testadasIds, setTestadasIds] = useState("");

  const [setor, setSetor] = useState("");
  const [loteCod, setLoteCod] = useState("");
  const [lote, setLote] = useState("");
  const [distrito, setDistrito] = useState("");
  const [quadra, setQuardra] = useState("");
  const [estadoBuscarLote, setEstadoBuscarLote] = useState(true);
  const [selectedValueCobertura, setSelectedValueCobertura] = useState([]);
  const [formData, setFormData] = useState([]);
  const [AddUnidades, setAddUnidades] = useState(0);
  const [dataBuscarLote, setDataBuscarLote] = useState([]);
  const [errors, setErrors] = useState({
    logradouroDaTestada: "",
    areaEspecial: "",
    testadaPrincipal: "",
    numUnidades: "",
  });
  const [errosValidacao, setErrosValidacao] = useState([]);
  const structForms = {
    logradouro: "",
    area: "",
    numReduzido: "",
    numero: "",
    complemento: "",
    bairro: "",
    apartamento: "",
    loteamento: "",
    tipo: "",
    cpfCnpj: "",
    nome: "",
    endereco: "",
  };

  const handleChangeData = (ids, index, e) => {
    const { id, value } = e.target;

    setFormData((prevData) => {
      return prevData.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [ids]: value,
            unidades: item.unidades.map((unidade, unidadeIdx) => {
              if (unidadeIdx === index) {
                return { ...unidade, [id]: value };
              }
              return unidade;
            }),
          };
        }
        return item;
      });
    });
  };
  const handleChangeCobertura = (index, indexNumUnidades) => {
    setSelectedValueCobertura((prevState) => {
      return prevState.map((item, i) => {
        if (i === indexNumUnidades) {
          return item.map((subItem, j) => {
            if (j === index) {
              return { ...subItem, checked: !subItem.checked };
            } else {
              return subItem;
            }
          });
        } else {
          return item;
        }
      });
    });
  };

  const handleChangeNumero = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      // Permite apenas números
      setNumero(value);
      setErrors((prevErrors) => ({ ...prevErrors, numero: "" }));
    }
  };

  const handleChangeSelectLogradouro = (e) => {
    setSelectValueLogradouro(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, select: "" }));
  };

  const handleChangeSelectTipo = (e) => {
    setSelectValueTipo(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, select: "" }));
  };

  const handleChangeRadioGroup = (event) => {
    if (valueRadioGroupTestada === event.target.value) {
      setValueRadioGroupTestada(0);
    } else {
      setValueRadioGroupTestada(parseInt(event.target.value));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      logradouroDaTestada: "",
      areaEspecial: "",
      testadaPrincipal: "",
      numUnidades: "",
    };

    if (selectValueLogradouro === "") {
      isValid = false;
      errors.logradouroDaTestada = "Por favor, selecione um logradouro.";
    }
    if (numUnidades <= 0) {
      isValid = false;
      errors.numUnidades = "Por favor, adicione o número de unidades.";
    }
    if (valueRadioGroupTestada === "") {
      isValid = false;
      errors.testadaPrincipal = "Por favor, adicione a testada principal.";
    }

    // Atualize o estado com as mensagens de erro
    setErrors(errors);

    return isValid;
  };

  const validateUnidade = (unidade) => {
    const errors = {};

    if (!unidade.logradouro || unidade.logradouro.trim() === "") {
      errors.logradouro = "Logradouro é obrigatório.";
    }

    if (!unidade.numero || unidade.numero <= 0) {
      errors.numero = "Número é obrigatório e deve ser maior que zero.";
    }

    if (!unidade.bairro || unidade.bairro.trim() === "") {
      errors.bairro = "Bairro é obrigatório.";
    }

    if (
      !unidade.proprietarioCpfCnpj ||
      unidade.proprietarioCpfCnpj.trim() === ""
    ) {
      errors.proprietarioCpfCnpj = "CPF/CNPJ do proprietário é obrigatório.";
    }

    if (!unidade.proprietarioNome || unidade.proprietarioNome.trim() === "") {
      errors.proprietarioNome = "Nome do proprietário é obrigatório.";
    }

    if (
      !unidade.proprietarioEndereco ||
      unidade.proprietarioEndereco.trim() === ""
    ) {
      errors.proprietarioEndereco = "Endereço do proprietário é obrigatório.";
    }

    if (!unidade.area || unidade.area.trim() === "") {
      errors.area = "Área é obrigatória.";
    }

    if (!unidade.numReduzido || unidade.numReduzido.trim() === "") {
      errors.numReduzido = "Número reduzido é obrigatório.";
    }

    if (!unidade.proprietarioTipo || unidade.proprietarioTipo.trim() === "") {
      errors.proprietarioTipo = "Tipo do proprietário é obrigatório.";
    }

    return Object.keys(errors).length === 0
      ? { Validacao: "Nem um erro" }
      : errors;
  };

  const toggleContinue = () => {
    // if (validateForm()) {

    let newFormData = Array.from(
      { length: parseInt(numUnidades) },
      () => structForms
    );
    if (dataBuscarLote.length > 0) {
      if (newFormData.length > 0) {
        newFormData[0] = dataBuscarLote[0];
      }
    }
    setFormData(newFormData);
    setAddUnidades(numUnidades);
    setContinueEstado(!continueEstado);
    // }
  };
  const ConsultaLote = async (id) => {
    const responseConsultarLote = await Api.getSearchXY(id ? id : numCodigo);

    if (responseConsultarLote.status !== 200) {
      setSetor("");
      setQuardra("");
      setLoteCod("");
      setLote("");
      setDistrito("");
      setNumUnidades("");

      setEstadoBuscarLote(false);
      setDataBuscarLote([]);

      window.alert("Id/Reduzido não encontrado");

      return 0;
    }

    const dadosProprietario = responseConsultarLote.data.proprietario;
    const dadosEndereco = responseConsultarLote.data.endereco;
    const dadosUnidade = responseConsultarLote.data.unidades;

    const obj = {
      logradouro: dadosEndereco.logradouro || "",
      area: dadosEndereco.area || "",
      numero: dadosEndereco.numero || "",
      complemento: dadosEndereco.complemento || "",
      loteamento: dadosEndereco.loteamLote || "",
      bairro: dadosEndereco.bairro || "",
      apartamento: dadosEndereco.apartamento || "",
      numReduzido: dadosProprietario.reduzido || "",
      tipo: dadosProprietario.tipo || "",
      cpfCnpj: dadosProprietario.cpfCnpj || "",
      nome: dadosProprietario.nome || "",
      endereco: dadosProprietario.endereco || "",
      proprietarioId: parseInt(dadosProprietario.id),
      unidades: dadosUnidade,
    };

    setDataBuscarLote([obj]);

    setSetor(responseConsultarLote.data.setorCod);
    setQuardra(responseConsultarLote.data.quadraCod);
    setLoteCod(responseConsultarLote.data.loteCod);
    setLote(numCodigo);
    setDistrito(responseConsultarLote.data.distritoCod);
    setNumUnidades(responseConsultarLote.data.qtdUnidades);

    setEstadoBuscarLote(true);
    return responseConsultarLote;
  };

  const handleSubmitData = (idAdv) => {
    const unidadesData = formData.map((item, index) => {
      const idsCoberturasChecked = selectedValueCobertura[index]
        .filter((cobertura) => cobertura.checked)
        .map((cobertura) => cobertura.cobertura);

      const unidade = {
        unidadeId: index + 1,
        coberturas: idsCoberturasChecked || [], // Coberturas selecionadas para esta unidade
        benfeitorias: [], // Exemplo: benfeitorias vazias, você pode ajustar conforme necessário
        logradouro: item.logradouro || "",
        numero: parseInt(item.numero || 0), // Convertendo para número inteiro
        complemento: item.complemento || "",
        area: item.area || "",
        numReduzido: item.numReduzido || "",
        bairro: item.bairro || "",
        apartamento: item.apartamento || "",
        loteamento: item.loteamento || "",
        proprietarioId: item.proprietarioId || 0,
        proprietarioNome: item.nome || "",
        proprietarioEndereco: item.endereco || "", // Ajustar conforme a estrutura real
        proprietarioCpfCnpj: item.cpfCnpj || "",
        proprietarioTipo: item.tipo || "",
      };

      const validate = validateUnidade(unidade);

      return { unidade, validate };
    });

    let errorsPorUnidade = { errors: {} };
    errorsPorUnidade = unidadesData.map((unidadeData) => ({
      unidadeId: unidadeData.unidade.unidadeId,
      errors: unidadeData.validate,
    }));

    const formsUnidadeValidado = unidadesData.map((unidadeData) => ({
      ...unidadeData.unidade,
    }));

    setErrosValidacao(errorsPorUnidade);

    console.log(formsUnidadeValidado);
    console.log(selectedValueCobertura);

    // Arrays de repetições de coberturas e unidades
    let arraysRepitidosCoberturas = [];
    let arraysRepitidosUnidade = [];

    // Mensagens de coberturas e unidades repetidas
    if (selectedValueCobertura && selectedValueCobertura.length > 0) {
      const aux = selectedValueCobertura.map((item, index2) => {
        const aux2 = item.filter((item2) => item2.checked);
        const objetoEdit = {
          cobertura: aux2.map((item) => {
            return item.cobertura;
          }),
          unidade: index2 + 1,
        };
        return objetoEdit;
      });

      const matrizcobertura =
        aux?.map((item) => {
          return { cobertura: item.cobertura };
        }) || [];
      let todasTestadas = false;
      if (!!selectedValueCobertura) {
        todasTestadas = selectedValueCobertura[0].map((item) => {
          return item.cobertura;
        });
      }
      if (todasTestadas.length) {
        for (let count = 0; count < todasTestadas.length; count++) {
          const arrayRepeatCobertura = {
            Cobertura: "",
            Unidade: [],
          };

          arrayRepeatCobertura.Cobertura = todasTestadas[count];
          arraysRepitidosCoberturas.push(arrayRepeatCobertura);

          for (let i = 0; i < matrizcobertura.length; i++) {
            for (let j = 0; j < matrizcobertura[i].cobertura.length; j++) {
              if (todasTestadas[count] === matrizcobertura[i].cobertura[j]) {
                arrayRepeatCobertura.Unidade.push(i + 1);
              }
            }
          }
        }
      }

      if (todasTestadas.length) {
        for (let count = 0; count < selectedValueCobertura.length; count++) {
          const arrayRepeatUnidade = {
            Unidade: "",
            Cobertura: [],
          };

          arrayRepeatUnidade.Unidade = count + 1;

          for (let index = 0; index < todasTestadas.length; index++) {
            for (
              let countCobertura = 0;
              countCobertura < matrizcobertura[count].cobertura.length;
              countCobertura++
            ) {
              if (
                matrizcobertura[count].cobertura[countCobertura] ===
                todasTestadas[index]
              ) {
                arrayRepeatUnidade.Cobertura.push(todasTestadas[index]);
              }
            }
          }
          arraysRepitidosUnidade.push(arrayRepeatUnidade);
        }
      }
    }

    // Estrutura final do objeto de salvamento
    const savePost = {
      adversidadeId: idAdv,
      loteId: parseInt(lote) || 0,
      setorCod: parseInt(numCodigo) || 0,
      quadraCod: parseInt(quadra) || 0,
      loteCod: parseInt(loteCod) || 0,
      somaArea: parseInt(formData.area) || 0, // Ajuste conforme necessário
      testadaPrincipal: valueRadioGroupTestada, // Verificar se valueRadioGroupTestada precisa ser ajustado para número
      testadas: testadasIds.map((item, index) => ({ eixo: 0, id: item })), // Ajuste conforme necessário
      //   especial: areaEspecial === "S",
      //   encravado: loteEscravo === 'S',
      tipo: numUnidades <= 1 ? "vazio" : "normal",
      unidades: formsUnidadeValidado,
    };

    return savePost;
    // Atualizar estado de mensagens
    // setMessage(newMessagesList);

    // const hasNoErrors = errorsPorUnidade?.every((erros) => {
    //   console.log(erros.errors)
    //   return erros.errors && erros.errors.Validacao === "Nem um erro"
    // }) ?? false;

    // console.log(hasNoErrors);
    // if (hasNoErrors && validateForm() && estadoBuscarLote) {
    //   console.log('Dados a serem enviados:', savePost);
    // } else {
    //   validateForm()
    //   console.log('Dados não foram enviados');
    // }
    // handleSetEstadoModal();
  };

  return (
    <InputContext.Provider
      value={{
        ConsultaLote,
        errors,
        setErrors,
        errosValidacao,
        setErrosValidacao,
        handleChangeNumero,
        handleChangeSelectLogradouro,
        handleChangeSelectTipo,
        validateForm,
        selectValueLogradouro,
        selectValueTipo,
        numero,
        setNumero,
        valueRadioGroup,
        setValueRadioGroup,
        handleChangeRadioGroup,
        valueRadioGroupTestada,
        setValueRadioGroupTestada,
        handleChangeRadioGroup,
        numUnidades,
        setNumUnidades,
        numCodigo,
        setNumCodigo,
        setor,
        loteCod,
        lote,
        distrito,
        quadra,
        estadoBuscarLote,
        selectedValueCobertura,
        setSelectedValueCobertura,
        handleChangeCobertura,
        toggleContinue,
        formData,
        setFormData,
        AddUnidades,
        setAddUnidades,
        handleChangeData,
        handleSubmitData,
        setTestadasIds,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};

export { ContextProviderInput, InputContext };
