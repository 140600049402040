import { useState, useEffect } from "react";
import { CardHeader, CardMedia, IconButton } from "@mui/material";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DoneIcon from '@mui/icons-material/Done';
import pica from 'pica';

export const RecipeReviewCard = ({ inviteStreetView, imagem, onDelete, onAdd }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    // Função para redimensionar a imagem usando pica
    const createThumbnail = async (file) => {
        const picaInstance = pica();
        const img = new Image();
        img.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
            img.onload = async () => {
                const canvas = document.createElement('canvas');
                const targetCanvas = document.createElement('canvas');
                const maxWidth = 200; // Define a largura máxima da miniatura

                // Redimensiona a imagem mantendo a proporção
                const scaleFactor = maxWidth / img.width;
                targetCanvas.width = maxWidth;
                targetCanvas.height = img.height * scaleFactor;

                await picaInstance.resize(img, targetCanvas);
                targetCanvas.toBlob((blob) => {
                    resolve(URL.createObjectURL(blob));
                }, 'image/jpeg');
            };
        });
    };

    useEffect(() => {
        if (imagem.file) {
            createThumbnail(imagem.file).then(setThumbnailUrl);
        } else {
            setThumbnailUrl(imagem.url); // Usa a URL original se a imagem já estiver redimensionada
        }
    }, [imagem]);

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '4px', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
                sx={{
                    width: '100%',
                    padding: '2px',
                    borderBottom: '1px solid #ccc',
                    '& .MuiCardHeader-action': {
                        width: '100%',
                        margin: 0,
                    },
                }}
                action={
                    <div style={{ width: '100%', display: 'flex' }}>
                        <IconButton aria-label="add" size="small" onClick={onAdd}
                            sx={{
                                backgroundColor: imagem.selected ? '#A1E8A1' : undefined,
                                '&:hover': { backgroundColor: imagem.selected ? '#A1E8A1' : undefined }
                            }}>
                            <DoneIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={onDelete}>
                            <DoNotDisturbOnIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                }
            />
            <div style={{ flexGrow: 1, position: 'relative', overflow: 'hidden', cursor: 'pointer' }} onClick={() => {
                inviteStreetView(imagem);
            }}>
                <CardMedia
                    component="img"
                    alt="Imagem do AWS"
                    height="140"
                    image={thumbnailUrl || imagem.url}
                />
            </div>
        </div>
    );
};
