import React, { useRef, useEffect, useState } from 'react';
import { ModalLote } from '../ModalLote/ModalLote.jsx';
import { Api } from '../../../../services/Api.js';
import ManegerVectorLayer from '../../../../Map/Component/VectorManager.js';
import { Button, Divider, Typography } from '@mui/material';
import { Legenda } from '../Sidebar/Legenda.jsx';
import { ManegerAlocacao } from '../ManegerAlocacao/ManegerAlocacao.jsx';
import { configurarCoordenadaCentral, calcularCentroide } from '../../../../Map/Hooks/FormaterCoordinates.jsx';
import { InputProvider } from '../ModalLote/ContextModalLote/ContextModalLote.jsx';

const MapaColetas = ({ initMapa }) => {
    const mapManger = useRef(null);
    const vectorManeger = useRef(null);
    const [onClose, setOnClose] = useState(false);
    const [dadosQuadra, setDadosQuadra] = useState(null);
    const [checkBox, setCheckBox] = useState([]);
    const [loteResult, setLoteResult] = useState([]);
    const [current, setCurrent] = useState('Setor');
    const [currentIdQuadra, setCurrentIdQuadra] = useState(null);
    const [quadraFlag, setQuadraFlag] = useState(null);
    const [sidebarListQuadras, setSidebarListQuadras] = useState([]);
    const [sidebarListSetores, setSidebarListSetores] = useState([]);

    const editColor = (item) => {
        return item.flag === 1 ? "rgb(19,100,181, 0.8)" :
            item.flag === 2 ? "rgb(41,143,33, 0.8)" :
                "rgb(229, 115, 115, 0.8)";
    }

    const editColorLote = (item) => {
        return item.flag === 1 ? "rgb(41,143,33, 0.8)" : "rgb(229, 115, 115, 0.8)";
    }

    const buscarColetores = async () => {
        try {
            const idColetorCodificado = sessionStorage.getItem('infoUsuarioId');
            const response = await Api.getColetoresId(idColetorCodificado);

            if (response && response.data && response.data.result) {
                const coletorFiltrado = response.data.result;
                return (coletorFiltrado[0].quadras);
            }
        } catch (error) {
            console.error('Erro ao buscar coletores:', error);
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = initMapa;
                const resultResponse = response.data.manchaUrbana;
                const coordendaCentral = configurarCoordenadaCentral(resultResponse.ponto_central.coordinates);

                if (!vectorManeger.current) {
                    vectorManeger.current = new ManegerVectorLayer(coordendaCentral);
                    vectorManeger.current.setMapElement(mapManger.current);
                    const ArrayDadosMancha = { coordenadas: [resultResponse.geom.coordinates], id: { manchaCod: resultResponse.geo_id } };

                    // Nova lógica para iniciar requisições 
                    // Adicione a chamada para requestSetores aqui
                }

            } catch (error) { console.log('Erro ao buscar mancha:', error) }
        }
        fetch();
        if (sessionStorage.getItem('infoUsuarioRole') === 'collector') {
            requestQuadras(); // Inicia com requestQuadras
        } else {
            requestSetores(); // Caso contrário, inicia com requestSetores
        }
    }, []);

    const requestSetores = async () => {
        try {
            const response = await Api.getMapaColeta();
            const resultResponse = response.data.setores.map((item) => {
                return { coordenadas: item.geom.coordinates, id: { cod: item.cod } }
            });

            setSidebarListSetores(resultResponse)

            if (vectorManeger.current) {
                console.log(resultResponse);
                vectorManeger.current.iniciarVectorLayer('Setor', resultResponse, (item) => {
                    requestQuadras(item.ID.cod);
                });

                setCheckBox((prev) => {
                    const novosCheckBoxes = resultResponse.map((setor) => ({
                        checked: true,
                        cod: setor.id.cod
                    }));
                    return [...prev, ...novosCheckBoxes];
                });

                setCurrent("Setor");
            } else {
                console.error('vectorManeger.current não está definido');
            }

        } catch (error) {
            console.error('Erro detalhado ao buscar setores:', error);
        }

    };

    const requestQuadras = async (setorCod) => {
        vectorManeger.current.removerVectorLayer('Setor');

        let response;
        if (sessionStorage.getItem('infoUsuarioRole') === 'collector') {
            response = await Api.getAllQuadras();
        } else {
            response = await Api.getQuadrasCod(setorCod);
        }
        const filterCoberturas = sessionStorage.getItem('infoUsuarioRole') === 'collector' ? await buscarColetores() : [];

        const resultResponse = response.data.quadras
            .filter(quadra => !filterCoberturas.length || filterCoberturas.includes(quadra.id))
            .map(item => ({
                coordenadas: item.geom.coordinates,
                id: { id: item.id, flag: item.flag, cod: item.cod, setorCod: setorCod || item.setor_cod }
            }));

        setSidebarListQuadras(resultResponse)
        const fuctionQuadra = (item) => {
            setQuadraFlag(item.ID.flag); // Armazenando o flag em um estado

            if (item.ID.flag > 0) {
                requestLotes(item.ID.id)
                setDadosQuadra(item.ID)
            } else {
                requestLotes(item.ID.id)
                setDadosQuadra(item.ID)
            }
        }

        vectorManeger.current.setCenterElement(configurarCoordenadaCentral(calcularCentroide(resultResponse[0].coordenadas[0][0])))
        vectorManeger.current.iniciarVectorLayer('Quadra', resultResponse, fuctionQuadra, editColor);
        setCurrent("Quadra")
    };

    const requestLotes = async (idQuadra) => {
        vectorManeger.current.removerVectorLayer('Quadra')
        setCurrentIdQuadra(idQuadra)
        try {
            const response = await Api.getLotesForQuadra(idQuadra);
            const resultResponse = response.data.lotes;
            if (resultResponse.length === 0) {
                alert("Lotes não encontrado!");
            }

            const dados = resultResponse.map((item) => { return { coordenadas: item.geom.coordinates, id: { id: item.id, flag: item.flag, geom: item.geom.coordinates } } })
            vectorManeger.current.setCenterElement(configurarCoordenadaCentral(calcularCentroide(dados[0].coordenadas[0][0])))
            const callLote = (item) => {
                setLoteResult(item)
                setOnClose(true)
            }
            vectorManeger.current.iniciarVectorLayer('Lote', dados, callLote, editColorLote);
            setCurrent("Lote")
        } catch (error) { console.log('Erro ao buscar lotes:', error) }
    };

    const requestEixos = async (idQuadra) => {
        vectorManeger.current.removerVectorLayer('Quadra')
        try {
            const response = await Api.getEixosForQuadra(idQuadra);
            const resultResponse = response.data.eixos;
            const dadods = resultResponse.map((item) => { return { coordenadas: [item.geom.coordinates], id: item.id } })
            vectorManeger.current.iniciarVectorLayer('Eixo', dadods, null, () => 'black');
        } catch (error) { console.log('Erro ao buscar lotes:', error) }
    };

    // Função para alterar o estado do checkbox
    const handleCheckboxChange = (index) => {
        if (checkBox[index].checked === true) { vectorManeger.current.removerVectorLayer('Setor') }
        else { vectorManeger.current.adicionarVectorLayer('Setor') }
        setCheckBox((prev) => prev.map((item, i) => (i === index ? { ...item, checked: !item.checked } : item)));
    };

    const toggleLayer = () => {
        if (current === 'Quadra') {
            vectorManeger.current.removerVectorLayer('Lote')
            vectorManeger.current.removerVectorLayer('Eixo')
            vectorManeger.current.removerVectorLayer('Quadra')
            vectorManeger.current.adicionarVectorLayer('Setor')
            setCurrent('Setor')
        }
        if (current === 'Lote') {
            vectorManeger.current.removerVectorLayer('Lote')
            vectorManeger.current.removerVectorLayer('Eixo')
            vectorManeger.current.adicionarVectorLayer('Quadra')
            setCurrent('Quadra')
        }
    }

    const resetMapQuadra = (setorCod) => {
        vectorManeger.current.removerVectorLayer('Lote')
        vectorManeger.current.removerVectorLayer('Eixo')
        vectorManeger.current.removerVectorLayer('Quadra')
        requestQuadras(setorCod)
    }

    const resetLoteLayer = () => {
        vectorManeger.current.removerVectorLayer('Lote');
        requestLotes(currentIdQuadra);
    };

    return (
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>

            <div style={{ width: 300, height: '100%', overflowY: 'auto' }}>
                <div> {(!(current === 'Setor') &&
                    !((sessionStorage.getItem('infoUsuarioRole') === 'collector') && (current === 'Quadra')))
                    && <Button style={{ marginTop: 10, marginInline: 10 }} onClick={toggleLayer}>voltar</Button>} </div>
                <Divider style={{ marginBlock: 10, marginInline: 10 }} />

                {current === 'Setor' && <>
                    <h4 style={{ marginLeft: 5, padding: 5 }}>Selecione o Setor</h4>
                    <div
                        style={{ padding: 5, display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {sidebarListSetores.map((item, index) => {
                            return (
                                <div key={index}
                                    style={{
                                        padding: 2, display: 'flex', flexDirection: 'column', gap: 15,
                                        cursor: 'pointer', background: 'rgb(229, 231, 235)', opacity: 0.8
                                    }}
                                    onMouseEnter={e => e.currentTarget.style.opacity = 1}
                                    onMouseLeave={e => e.currentTarget.style.opacity = 0.8}
                                    onClick={() => requestQuadras(item.id.cod)}>
                                    <Divider />
                                    <Typography variant="body1" color="textSecondary" sx={{ paddingInline: 2 }}>
                                        Setor: {item.id.cod}
                                    </Typography>
                                    <Divider />
                                </div>)
                        })}
                    </div>
                    {/* <SetoresFiltros indiceSetores={checkBox.length} checkBox={checkBox} handleCheckboxChange={handleCheckboxChange} />
                    <Divider style={{ marginBlock: 10, marginInline: 10 }} /> */}
                </>}
                {!(current === 'Setor') && <Legenda current={current} />}
                {current === "Quadra" && <>
                    <h4 style={{ marginLeft: 5, padding: 5 }}>Selecione a Quadra</h4>
                    <div
                        style={{ padding: 5, display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {sidebarListQuadras.map((item, index) => {
                            return (
                                <div key={index}
                                    style={{
                                        padding: 2, display: 'flex', flexDirection: 'column', gap: 15,
                                        cursor: 'pointer', background: 'rgb(229, 231, 235)', opacity: 0.8
                                    }}
                                    onMouseEnter={e => e.currentTarget.style.opacity = 1}
                                    onMouseLeave={e => e.currentTarget.style.opacity = 0.8}
                                    onClick={() => {
                                        requestLotes(item.id.id)
                                        setDadosQuadra(item.id)
                                    }}>
                                    <Divider />
                                    <Typography variant="body1" color="textSecondary" sx={{ paddingInline: 2 }}>
                                        Quadra: {item.id.cod}
                                    </Typography>
                                    <Divider />
                                </div>)
                        })}
                    </div>
                </>}
                {sessionStorage.getItem('infoUsuarioRole') !== 'collector' && current === "Lote" && <h4 style={{ marginLeft: 5, padding: 5 }}>{quadraFlag ? 'Selecione o Lote' : 'Selecione o Coletor'}</h4>}
                {(current === 'Lote' && dadosQuadra) && <div style={{ height: '60%' }}> <ManegerAlocacao dadosQuadra={dadosQuadra} resetMapQuadra={resetMapQuadra} /> </div>}
                {current === "QuadraSelect" && <h4 style={{ marginLeft: 5, padding: 5 }}>Selecione o Lote</h4>}
            </div>
            <div style={{ display: 'flex', width: '100%', height: '100%' }} ref={mapManger} />
            <InputProvider>
                {onClose && <ModalLote open={onClose} onClose={() => setOnClose(false)} returnLoteColeta={loteResult} resetLoteLayer={resetLoteLayer} />}
            </InputProvider>
        </div>
    );
};

export default MapaColetas;