import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  InputComponent,
  SelectComponent,
} from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Components/Inputs";
import {
  Box,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import { MapContextAdv } from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Context/FuncoesMapa";
import { InputContext } from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Context/ContextInputs";
import "./Unidades.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

type Props = {
  onRemove: (index: number) => void;
  tipoAdversidade?: string;
};

export function Unidades({ onRemove, tipoAdversidade }: Props) {
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { coberturas, colorsCoberturas } = React.useContext(MapContextAdv);
  const { errosValidacao } = React.useContext(InputContext);

  const unidades = getValues("unidades") || [];
  const testadas = getValues("testadas") || [];
  const lotecod_novo = getValues("lotecod_novo") || "";

  React.useEffect(() => {
    const unidadesFromApi = getValues("unidades");
    if (unidadesFromApi) {
      unidadesFromApi.forEach((unidade: any, index: number) => {
        setValue(`unidades.${index}.numreduzido`, unidade.numreduzido);
        setValue(
          `unidades.${index}.enderecologradouro`,
          unidade.enderecologradouro
        );
        checkNovoLogradouro(unidade, index);
        setValue(`unidades.${index}.endereconumero`, unidade.endereconumero);
        setValue(
          `unidades.${index}.enderecocomplemento`,
          unidade.enderecocomplemento
        );
        setValue(`unidades.${index}.enderecobairro`, unidade.enderecobairro);

        setValue(
          `unidades.${index}.proprietariotipo`,
          unidade.proprietariotipo
        );
        setValue(
          `unidades.${index}.proprietariocpfcnpj`,
          unidade.proprietariocpfcnpj
        );

        setValue(
          `unidades.${index}.proprietarionome`,
          unidade.proprietarionome
        );
        setValue(`unidades.${index}.lotecod_novo`, lotecod_novo);
        setValue(`unidades.${index}.unidadecod_novo`, index + 1);
      });
    }
  }, [unidades, setValue]);

  const handleChangeCobertura = (coberturaId: number, unidadeIndex: number) => {
    const unidadeCoberturas =
      getValues(`unidades.${unidadeIndex}.coberturas`) || [];
    const coberturaIndex = unidadeCoberturas.findIndex(
      (c: any) => c.id === coberturaId
    );

    if (coberturaIndex !== -1) {
      // Se a cobertura já existe, remova-a (deseleção)
      const newCoberturas = unidadeCoberturas.filter(
        (c: any) => c.id !== coberturaId
      );
      setValue(`unidades.${unidadeIndex}.coberturas`, newCoberturas);
    } else {
      // Se a cobertura não existe, adicione-a (seleção)
      setValue(`unidades.${unidadeIndex}.coberturas`, [
        ...unidadeCoberturas,
        { id: coberturaId },
      ]);
    }
  };

  const checkNovoLogradouro = (unidade?: any, index?: number) => {
    if (unidade) {
      if (unidade.length === 0) return;

      const logradouroAtual = unidade.enderecologradouro;
      const testadaCorrespondente = testadas.find(
        (testada: any) => testada.eixoProximo.logradouronome === logradouroAtual
      );

      if (testadaCorrespondente) {
        setValue(`unidades.${index}.enderecologradouro_novo`, logradouroAtual);
      } else {
        setValue(`unidades.${index}.enderecologradouro_novo`, "");
      }
    } else {
      if (unidades.length === 0) return;
      unidades.forEach((unidade: any, index: number) => {
        const logradouroAtual = unidade.enderecologradouro;
        const testadaCorrespondente = testadas.find(
          (testada: any) =>
            testada.eixoProximo.logradouronome === logradouroAtual
        );

        if (testadaCorrespondente) {
          setValue(
            `unidades.${index}.enderecologradouro_novo`,
            logradouroAtual
          );
        }
      });
    }
  };

  React.useEffect(() => {
    checkNovoLogradouro();
  }, [unidades, testadas, setValue]);

  if (!unidades.length) {
    return <p>Nenhuma unidade encontrada.</p>;
  }

  return (
    <>
      {unidades &&
        unidades.map((unidade: any, indexNumUnidades: number) => {
          const unidadeCoberturas =
            watch(`unidades.${indexNumUnidades}.coberturas`) || [];

          return (
            <form key={unidade.id} className='Unidades-form'>
              <div className='Unidades-flex-1'>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5 className='Unidades-margin-h5'>
                    Unidade {indexNumUnidades + 1}
                  </h5>
                  <Button
                    onClick={() => onRemove(indexNumUnidades)}
                    color='error'
                    size='large'
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>

                <div className='Unidades-form-row'>
                  <Controller
                    name={`unidades.${indexNumUnidades}.numreduzido`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='Id/Reduzido'
                        id='numreduzido'
                        {...field}
                        errors={
                          errosValidacao[indexNumUnidades]?.errors?.numreduzido
                        }
                        readOnly
                        required
                      />
                    )}
                  />
                  {tipoAdversidade === "R" && (
                    <>
                      <Controller
                        name={`unidades.${indexNumUnidades}.lotecod`}
                        control={control}
                        render={({ field }) => (
                          <InputComponent
                            label='Lote antigo'
                            id='lotecod'
                            {...field}
                            errors={
                              errosValidacao[indexNumUnidades]?.errors?.lotecod
                            }
                            readOnly
                            required
                          />
                        )}
                      />
                      <Controller
                        name={`unidades.${indexNumUnidades}.unidadecod`}
                        control={control}
                        render={({ field }) => (
                          <InputComponent
                            label='Unidade antiga'
                            id='unidadecod'
                            {...field}
                            errors={
                              errosValidacao[indexNumUnidades]?.errors
                                ?.unidadecod
                            }
                            readOnly
                            required
                          />
                        )}
                      />
                      <Controller
                        name={`unidades.${indexNumUnidades}.lotecod_novo`}
                        control={control}
                        render={({ field }) => (
                          <InputComponent
                            label='Lote novo'
                            id='lotecod_novo'
                            {...field}
                            errors={
                              errosValidacao[indexNumUnidades]?.errors
                                ?.lotecod_novo
                            }
                            readOnly
                            required
                          />
                        )}
                      />
                      <Controller
                        name={`unidades.${indexNumUnidades}.unidadecod_novo`}
                        control={control}
                        render={({ field }) => (
                          <InputComponent
                            label='Unidade nova'
                            id='unidadecod_novo'
                            {...field}
                            errors={
                              errosValidacao[indexNumUnidades]?.errors
                                ?.unidadecod_novo
                            }
                          />
                        )}
                      />
                    </>
                  )}
                </div>

                <div className='Unidades-form-row'>
                  <Controller
                    name={`unidades.${indexNumUnidades}.enderecologradouro`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='Logradouro atual'
                        id='enderecologradouro'
                        {...field}
                        errors={
                          errosValidacao[indexNumUnidades]?.errors
                            ?.proprietarioEndereco
                        }
                        readOnly
                        required
                      />
                    )}
                  />
                  <Controller
                    name={`unidades.${indexNumUnidades}.enderecologradouro_novo`}
                    control={control}
                    render={({ field }) => (
                      <SelectComponent
                        label='Novo logradouro'
                        id='enderecologradouro_novo'
                        {...field}
                        value={field.value || ""}
                        options={testadas.map((testada: any) => ({
                          value: testada.eixoProximo.logradouronome,
                          label: testada.eixoProximo.logradouronome,
                        }))}
                        errors={
                          (errors.unidades as any)?.[indexNumUnidades]
                            ?.enderecologradouro_novo?.message
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`unidades.${indexNumUnidades}.endereconumero`}
                    control={control}
                    // rules={{ required: "Campo obrigatório" }}
                    render={({ field }) => (
                      <InputComponent
                        label='Número'
                        id='endereconumero'
                        {...field}
                        errors={
                          (errors.unidades as any)?.[indexNumUnidades]
                            ?.endereconumero?.message
                        }
                      />
                    )}
                  />
                </div>

                <div className='Unidades-form-row'>
                  <Controller
                    name={`unidades.${indexNumUnidades}.enderecocomplemento`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='Complemento'
                        id='enderecocomplemento'
                        {...field}
                        errors={
                          (errors.unidades as any)?.[indexNumUnidades]
                            ?.enderecocomplemento?.message
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`unidades.${indexNumUnidades}.enderecobairro`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='Bairro'
                        id='enderecobairro'
                        {...field}
                        errors={
                          (errors.unidades as any)?.[indexNumUnidades]
                            ?.enderecobairro?.message
                        }
                      />
                    )}
                  />

                  <Controller
                    name={`unidades.${indexNumUnidades}.areaconstrucao`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='Área de construção (m²)'
                        id='areaconstrucao'
                        {...field}
                        errors={
                          errosValidacao[indexNumUnidades]?.errors
                            ?.areaconstrucao
                        }
                        required
                        readOnly
                      />
                    )}
                  />
                </div>

                <Divider />

                <h5 className='Unidades-margin-h5'>Dados do Proprietário</h5>
                <div className='Unidades-form-row'>
                  <Controller
                    name={`unidades.${indexNumUnidades}.proprietariotipo`}
                    control={control}
                    render={({ field }) => (
                      <SelectComponent
                        label='Tipo'
                        id='proprietariotipo'
                        {...field}
                        options={[
                          { value: "F", label: "Pessoa Física" },
                          {
                            value: "J",
                            label: "Pessoa Jurídica",
                          },
                        ]}
                        errors={
                          errosValidacao[indexNumUnidades]?.errors
                            ?.proprietariotipo
                        }
                        disabled
                        required
                      />
                    )}
                  />
                  <Controller
                    name={`unidades.${indexNumUnidades}.proprietariocpfcnpj`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='CPF/CNPJ'
                        id='proprietariocpfcnpj'
                        {...field}
                        value={field.value || ""}
                        errors={
                          errosValidacao[indexNumUnidades]?.errors
                            ?.proprietariocpfcnpj
                        }
                        readOnly={
                          `unidades.${indexNumUnidades}.proprietariocpfcnpj`
                            ? true
                            : false
                        }
                        required={
                          `unidades.${indexNumUnidades}.proprietariocpfcnpj`
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`unidades.${indexNumUnidades}.proprietarionome`}
                    control={control}
                    render={({ field }) => (
                      <InputComponent
                        label='Nome'
                        id='proprietarionome'
                        {...field}
                        errors={
                          errosValidacao[indexNumUnidades]?.errors
                            ?.proprietarionome
                        }
                        readOnly={
                          `unidades.${indexNumUnidades}.proprietarionome`
                            ? true
                            : false
                        }
                        required={
                          `unidades.${indexNumUnidades}.proprietarionome`
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  padding: "10px",
                  marginTop: "20px",
                }}
              >
                <Grid item xs={12} md={3}>
                  {coberturas.map((cobertura: any, index: number) => {
                    const isSelected = unidadeCoberturas.some(
                      (c: any) => c.id === cobertura.id
                    );

                    return (
                      <Box
                        key={cobertura.id}
                        sx={{
                          width: "100%",
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          bgcolor: "background.paper",
                          color: "text.secondary",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          flexDirection: "column",
                          maxWidth: "250px",
                        }}
                      >
                        <div className='advEnd-vectorization-info-box'>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={isSelected}
                                onClick={() =>
                                  handleChangeCobertura(
                                    cobertura.id,
                                    indexNumUnidades
                                  )
                                }
                              />
                            }
                            label=''
                            sx={{
                              height: "0",
                              margin: "10px",
                              marginLeft: "0",
                            }}
                          />
                          <h5 style={{ margin: "15px", marginLeft: "45px" }}>
                            Cobertura {cobertura.id}
                          </h5>
                          <div
                            style={{
                              background: colorsCoberturas[index],
                              height: "20px",
                              width: "20px",
                              margin: "15px",
                              marginLeft: "0",
                            }}
                          />
                        </div>
                      </Box>
                    );
                  })}
                </Grid>
              </Box>
            </form>
          );
        })}
    </>
  );
}
