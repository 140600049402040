import React from "react";
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ArrowLeftRounded, ArrowRightRounded } from "@material-ui/icons";
import { styled } from "@mui/material/styles";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-root": {
    marginRight: 0,
  },
  "& .MuiTypography-root": {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginBottom: 0,
  },
}));

const Pagination = ({
  unidadesNumber,
  functionPagination,
  paginationActive,
}) => {
  const pagesArray = Array.from(
    { length: unidadesNumber },
    (_, index) => index + 1
  );

  return (
    <>
      {pagesArray.map((item, index) => (
        <Button
          key={index}
          onClick={() => {
            functionPagination(index);
          }}
          variant={paginationActive === index ? "contained" : "outlined"}
          sx={{ width: "1rem", padding: "0.2rem 1rem", minWidth: 0 }}
        >
          {item}
        </Button>
      ))}
    </>
  );
};

class TabUnidadeImobiliaria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      unidadesData: this.inicializarUnidadesData(
        props.unidades,
        props.unidadesDataAtual
      ),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.unidades !== this.props.unidades ||
      prevProps.unidadesDataAtual !== this.props.unidadesDataAtual
    ) {
      this.setState({
        unidadesData: this.inicializarUnidadesData(
          this.props.unidades,
          this.props.unidadesDataAtual
        ),
      });
    }

    if (prevProps.save !== this.props.save && this.props.save) {
      this.props.onUpdate(this.state.unidadesData);
    }
  }

  inicializarUnidadesData(unidades, unidadesDataAtual) {
    return unidades.map((unidade, index) => {
      let pontoDeInteresse = false;

      if (unidade?.nomeInteresse || false) {
        pontoDeInteresse = true;
      }

      return {
        id: unidade.id || "",
        utilizacao:
          unidade.utilizacao || unidadesDataAtual?.[index]?.utilizacao || "",
        tipo: unidade.tipo || unidadesDataAtual?.[index]?.tipo || "",
        alinhamento:
          unidade.alinhamento || unidadesDataAtual?.[index]?.alinhamento || "",
        posicao: unidade.posicao || unidadesDataAtual?.[index]?.posicao || "",
        classificacao:
          unidade.classificacao ||
          unidadesDataAtual?.[index]?.classificacao ||
          "",
        piso: unidade.piso || unidadesDataAtual?.[index]?.piso || "",
        pontoDeInteresse: pontoDeInteresse || false,
        idInteresse:
          unidade.idInteresse || unidadesDataAtual?.[index]?.idInteresse || "",
        tipoInteresse:
          unidade.tipoInteresse ||
          unidadesDataAtual?.[index]?.tipoInteresse ||
          "",
        nomeInteresse:
          unidade.nomeInteresse ||
          unidadesDataAtual?.[index]?.nomeInteresse ||
          "",
      };
    });
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newUnidades = [...prevState.unidadesData];
      newUnidades[prevState.currentIndex] = {
        ...newUnidades[prevState.currentIndex],
        [field]: value,
      };
      return { unidadesData: newUnidades };
    });
  };

  handlePagination = (index) => {
    this.setState({ currentIndex: index });
  };

  render() {
    const { unidades } = this.props;
    const { currentIndex, unidadesData } = this.state;

    if (!unidades || unidades.length === 0) {
      return <Typography>Nenhuma unidade disponível</Typography>;
    }

    const currentUnidade = unidades[currentIndex];
    const currentUnidadeData = unidadesData[currentIndex];

    const options = {
      utilizacao: [
        { label: "Residencial", value: "RESIDENCIAL" },
        { label: "Comercial", value: "COMERCIAL" },
        { label: "Serviços", value: "SERVICOS" },
        { label: "Industrial", value: "INDUSTRIAL" },
        { label: "Religioso", value: "RELIGIOSO" },
        { label: "Pública", value: "PUBLICA" },
        { label: "Mista", value: "MISTA" },
        { label: "Agropecuária", value: "AGROPECUARIA" },
        { label: "Terreno sem Uso", value: "TERRENO_SEM_USO" },
        { label: "Outros", value: "OUTROS" },
      ],
      tipo: [
        { label: "Casa/Sobrado", value: "Casa/Sobrado" },
        { label: "Apartamento", value: "Apartamento" },
        { label: "Sala/Loja", value: "Sala/Loja" },
        { label: "Fábrica/Indústria", value: "Fábrica/Indústria" },
        { label: "Galpão", value: "Galpão" },
        { label: "Telheiros", value: "Telheiros" },
        { label: "Misto", value: "Misto" },
        { label: "Outros", value: "Outros" },
      ],
      alinhamento: [
        { label: "Frente Alinhada", value: "FRENTE_ALINHADA" },
        { label: "Frente Recuada", value: "FRENTE_RECUADA" },
        { label: "Frente Avançada", value: "FRENTE_AVANCADA" },
        { label: "Fundos", value: "FUNDOS" },
      ],
      posicao: [
        { label: "Isolada", value: "ISOLADA" },
        { label: "Germinada", value: "GEMINADA" },
        { label: "Conjugada", value: "CONJUGADA" },
        { label: "Cond. Vertical", value: "COND. VERTICAL" },
        { label: "Cond. Horizontal", value: "COND. HORIZONTAL" },
      ],
      classificacao: [
        { label: "Popular", value: "POPULAR" },
        { label: "Baixo", value: "BAIXO" },
        { label: "Normal", value: "NORMAL" },
        { label: "Alto", value: "ALTO" },
        { label: "Não se aplica", value: "NAO SE APLICA" },
      ],
      piso: [
        { label: "Terra", value: "TERRA" },
        { label: "Cimento", value: "CIMENTO" },
        { label: "Cerâmica", value: "CERAMICA" },
        { label: "Pedra/Granito", value: "PEDRA/GRANITO" },
      ],
      tipoInteresse: [
        { label: "Saúde", value: "Saúde" },
        { label: "Hotel Pousada", value: "Hotel Pousada" },
        { label: "Banco", value: "Banco" },
        { label: "Áreas públicas", value: "Áreas públicas" },
        { label: "Instalações", value: "Instalações" },
        { label: "Educação", value: "Educação" },
        { label: "Cemitério", value: "Cemitério" },
        { label: "Delegacia", value: "Delegacia" },
        { label: "Instalação militar", value: "Instalação militar" },
        { label: "Indústria", value: "Indústria" },
        { label: "Sindicato", value: "Sindicato" },
        { label: "Loja maçônica", value: "Loja maçônica" },
        {
          label: "Clube de serviço/social",
          value: "Clube de serviço/social",
        },
        { label: "Associações", value: "Associações" },
        { label: "Religioso", value: "Religioso" },
      ],
    };

    return (
      <Box display='flex' flexDirection='column' gap={2}>
        <Box
          display='flex'
          flexDirection='row'
          sx={{ flexBasis: "100%", alignItems: "center" }}
        >
          <div style={{ width: "100%" }}>
            <h4>Id/Reduzido: {currentUnidade.id}</h4>
            <Box
              display='flex'
              flexDirection='row'
              gap={1}
              sx={{ flexBasis: "100%" }}
            >
              <Typography
                gutterBottom
                sx={{
                  color: "text.secondary",
                  fontSize: 12,
                  marginBottom: 0,
                }}
              >
                LoteCod: {currentUnidade.loteCod}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: "text.secondary",
                  fontSize: 12,
                  marginBottom: 0,
                }}
              >
                /
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: "text.secondary",
                  fontSize: 12,
                  marginBottom: 0,
                }}
              >
                UnidadeCod: {currentUnidade.unidadeCod}
              </Typography>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={currentUnidadeData?.pontoDeInteresse || false}
                  onChange={(event) =>
                    this.handleChange("pontoDeInteresse", event.target.checked)
                  }
                />
              }
              label='Ponto de interesse'
            />
          </div>
        </Box>

        <Box display='flex' flexDirection='row' gap={2}>
          <TextField
            select
            label='Tipo'
            sx={{ flexBasis: "100%" }}
            value={currentUnidadeData?.tipo || ""}
            onChange={(e) => this.handleChange("tipo", e.target.value)}
          >
            {options.tipo.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label='Utilização'
            sx={{ flexBasis: "100%" }}
            value={currentUnidadeData?.utilizacao || ""}
            onChange={(e) => this.handleChange("utilizacao", e.target.value)}
          >
            {options.utilizacao.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box display='flex' flexDirection='row' gap={2}>
          <TextField
            select
            label='Alinhamento'
            fullWidth
            value={currentUnidadeData?.alinhamento || ""}
            onChange={(e) => this.handleChange("alinhamento", e.target.value)}
          >
            {options.alinhamento.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label='Posição'
            fullWidth
            value={currentUnidadeData?.posicao || ""}
            onChange={(e) => this.handleChange("posicao", e.target.value)}
          >
            {options.posicao.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box display='flex' flexDirection='row' gap={2}>
          <TextField
            select
            label='Classificação'
            fullWidth
            value={currentUnidadeData?.classificacao || ""}
            onChange={(e) => this.handleChange("classificacao", e.target.value)}
          >
            {options.classificacao.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label='Piso Externo'
            fullWidth
            value={currentUnidadeData?.piso || ""}
            onChange={(e) => this.handleChange("piso", e.target.value)}
          >
            {options.piso.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {currentUnidadeData?.pontoDeInteresse && (
          <>
            <Typography
              gutterBottom
              sx={{
                color: "text.secondary",
                fontSize: 14,
                marginBottom: 0,
              }}
            >
              Ponto de Interesse:
            </Typography>
            <Box display='flex' flexDirection='row' gap={2}>
              <TextField
                select
                label='Tipo'
                fullWidth
                value={currentUnidadeData?.tipoInteresse || ""}
                onChange={(e) =>
                  this.handleChange("tipoInteresse", e.target.value)
                }
              >
                {options.tipoInteresse.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Nome'
                fullWidth
                value={currentUnidadeData?.nomeInteresse || ""}
                onChange={(e) =>
                  this.handleChange("nomeInteresse", e.target.value)
                }
              />
            </Box>
          </>
        )}

        {unidades.length > 1 && (
          <div
            style={{
              position: "absolute",
              bottom: 10,
              left: 280,
              width: "100%",
              height: "2rem",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <IconButton
              aria-label='voltar'
              size='small'
              onClick={() => {
                if (currentIndex > 0) {
                  this.handlePagination(currentIndex - 1);
                }
              }}
            >
              <ArrowLeftRounded fontSize='small' />
            </IconButton>
            <Pagination
              unidadesNumber={unidades.length}
              functionPagination={this.handlePagination}
              paginationActive={currentIndex}
            />
            <IconButton
              aria-label='avançar'
              size='small'
              onClick={() => {
                if (currentIndex < unidades.length - 1) {
                  this.handlePagination(currentIndex + 1);
                }
              }}
            >
              <ArrowRightRounded fontSize='small' />
            </IconButton>
          </div>
        )}
      </Box>
    );
  }
}

export default TabUnidadeImobiliaria;
