import React from "react";
import {
  Container,
  Card,
  Title,
  Divider,
  Box,
  RowContainer,
  ActionContainer,
  Button,
} from "./styles";
import { InputComponent } from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Components/Inputs";
import { Modal } from "../../../../Components/common";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AdversidadesGlobalResponse } from "../../../../services/getAdversidadesGlobalService";
import { postReportAdversidadesService } from "../../../../services/postReportAdversidadesService";
import { toast } from "react-toastify";

type Props = {
  handleProsseguirR: (
    numReduzido: number | null,
    lotecod_novo: number | null
  ) => void;
  lotes: AdversidadesGlobalResponse["lotes"];
  adversidade: AdversidadesGlobalResponse;
};

interface FormData {
  numCodigo: string | null;
  distritoCod: string | null;
  setorCod: string | null;
  quadraCod: string | null;
  loteCod: string | null;
  isRemembramento: string | null;
}

interface ReportData {
  id: number;
  nome_lote: string;
  quadraCod: string;
  setorCod: string;
  observacao: string;
}

export function TableRecadastramento({
  handleProsseguirR,
  lotes,
  adversidade,
}: Props) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const {
    control: reportControl,
    handleSubmit: handleSubmitReport,
    formState: { errors: reportErros },
  } = useForm<ReportData>({
    defaultValues: {
      id: adversidade.adversidade.id,
      setorCod: String(adversidade.adversidade.setor_cod),
      quadraCod: String(adversidade.adversidade.quadra_cod),
      nome_lote: adversidade.adversidade.name,
    },
  });

  const watchIsRemembramento = watch("isRemembramento");

  async function handleClickProsseguirR(data: FormData) {
    if (data.isRemembramento && data.isRemembramento !== "nao") {
      const lotecod = lotes.filter(
        (lote) => lote.reduzido === Number(data.isRemembramento)
      )[0].lote.loteCod;

      handleProsseguirR(Number(data.isRemembramento), Number(lotecod));
    }
  }

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  function handleReport() {
    handleOpen();
  }
  async function handleClickReportar(data: ReportData) {
    try {
      const response = await toast.promise(
        postReportAdversidadesService({
          lote_id_to_review: Number(data.id),
          nome_lote: data.nome_lote,
          flag: adversidade.adversidade.flag,
          observacoes: data.observacao,
          tipo: adversidade.adversidade.tipo,
        }),
        {
          pending: "Reportando adversidade...",
          success: "Adversidade reportada com sucesso!",
          error: {
            //@ts-ignore
            render({ data }) {
              return `${
                //@ts-ignore
                data.message
              }`;
            },
          },
        }
      );
      if (response) {
        handleClose();
      }
    } catch (error) {
      console.log("🚀 ~ handleClickReportar ~ error:", error);
    }
  }

  React.useEffect(() => {
    handleProsseguirR(null, null);
  }, [watchIsRemembramento]);

  return (
    <>
      <Container>
        <Card>
          <Title>
            FORAM IDENTIFICADOS OS SEGUINTES IMÓVEIS PARA O REMEMBRAMENTO,
            SELECIONE O IMOVEL REMANESCENTE PARA PROSSEGUIR
          </Title>
          <Divider />
          <div style={{ width: "100%" }}>
            <Box>
              <h5>Escolha do Lote:</h5>
              {lotes.map((lote, index) => (
                <RowContainer
                  key={lote.lote.id}
                  style={{
                    alignItems: "end",
                  }}
                >
                  <Controller
                    control={control}
                    name='numCodigo'
                    render={({ field }) => (
                      <InputComponent
                        label='Id/Reduzido'
                        id='numCodigo'
                        {...field}
                        value={lote.reduzido || ""}
                        required
                        readOnly
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='distritoCod'
                    render={({ field }) => (
                      <InputComponent
                        label='Distrito'
                        id='distritoCod'
                        {...field}
                        value={lote.lote.distritoCod || ""}
                        required
                        readOnly
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='setorCod'
                    render={({ field }) => (
                      <InputComponent
                        label='Setor'
                        id='setorCod'
                        {...field}
                        required
                        readOnly
                        value={lote.lote.setorCod || ""}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='quadraCod'
                    render={({ field }) => (
                      <InputComponent
                        label='Quadra'
                        id='setorCod'
                        {...field}
                        required
                        readOnly
                        value={lote.lote.quadraCod || ""}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='loteCod'
                    render={({ field }) => (
                      <InputComponent
                        label='Lote'
                        id='loteCod'
                        {...field}
                        required
                        readOnly
                        value={lote.lote.loteCod}
                      />
                    )}
                  />
                  {/* RADIO GROUP */}
                  <Controller
                    control={control}
                    name='isRemembramento'
                    render={({ field }) => (
                      <div>
                        <RadioGroup
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => field.onChange(e.target.value)}
                          aria-labelledby='demo-controlled-radio-buttons-group'
                        >
                          <FormControlLabel
                            key={`${index}`}
                            value={`${lote.reduzido}`}
                            control={<Radio />}
                            label=''
                          />
                        </RadioGroup>
                      </div>
                    )}
                  />
                </RowContainer>
              ))}

              <RowContainer
                style={{
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Controller
                  control={control}
                  name='isRemembramento'
                  rules={{ required: "Selecione uma opção" }}
                  render={({ field }) => (
                    <div>
                      <RadioGroup
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange("nao")}
                        aria-labelledby='demo-controlled-radio-buttons-group'
                      >
                        <FormControlLabel
                          value={"nao"}
                          control={<Radio />}
                          label='Não, não é um caso de remembramento'
                        />
                      </RadioGroup>
                    </div>
                  )}
                />

                <ActionContainer>
                  <Button
                    onClick={
                      watchIsRemembramento === "nao"
                        ? handleReport
                        : handleSubmit(handleClickProsseguirR)
                    }
                  >
                    {watchIsRemembramento === "nao" ? "Reportar" : "Prosseguir"}
                  </Button>
                </ActionContainer>
              </RowContainer>
              {errors.isRemembramento && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8rem",
                    marginTop: "5px",
                  }}
                >
                  {errors.isRemembramento.message}
                </p>
              )}
            </Box>
          </div>
        </Card>
      </Container>
      <Modal
        open={openModal}
        handleClose={handleClose}
        title='Motivo de Report'
      >
        <Controller
          control={reportControl}
          name='id'
          render={({ field }) => (
            <InputComponent
              label='Id da Adversidade'
              id='id'
              {...field}
              required
              readOnly
            />
          )}
        />
        <Controller
          control={reportControl}
          name='setorCod'
          render={({ field }) => (
            <InputComponent
              label='Setor identificado'
              id='setorCod'
              {...field}
              required
              readOnly
            />
          )}
        />
        <Controller
          control={reportControl}
          name='quadraCod'
          render={({ field }) => (
            <InputComponent
              label='Quadra identificada'
              id='quadraCod'
              {...field}
              required
              readOnly
            />
          )}
        />
        <Controller
          control={reportControl}
          name='nome_lote'
          render={({ field }) => (
            <InputComponent
              label='Rótulo'
              id='nome_lote'
              {...field}
              required
              readOnly
            />
          )}
        />
        <Controller
          control={reportControl}
          name='observacao'
          render={({ field }) => (
            <InputComponent
              label='Observações'
              id='observacao'
              {...field}
              textarea
            />
          )}
        />
        <Button onClick={handleSubmitReport(handleClickReportar)}>
          Enviar
        </Button>
      </Modal>
    </>
  );
}
