import React from "react";

import Typography from "@mui/material/Typography";
import MuiModal from "@mui/material/Modal";

import { Container } from "./styles";

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
};

export function Modal({ open, handleClose, title, children }: Props) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Container>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {title}
        </Typography>
        {children}
      </Container>
    </MuiModal>
  );
}
