const url = process.env.REACT_APP_API;

type Testada = {
  testadaId: number | null;
  eixoId: number;
};

type Unidade = {
  unidadeId: number;
  coberturas: string[] | null;
  benfeitorias: string[] | null;
  logradouro: number | null;
  numero: number | null;
  complemento: string;
  bairro: string | null;
  proprietarioId: number;
  proprietarioNome: string;
  proprietarioEndereco?: string;
  proprietarioCpfCnpj: string;
  proprietarioTipo: "F" | "J"; // 'F' para pessoa física, 'J' para jurídica
  unidadeCod: number;
};

type Props = {
  data: {
    adversidadeId: number;
    loteId: number;
    setorCod: number;
    quadraCod: number;
    loteCod: number;
    somaArea: number;
    testadaPrincipal: number;
    testadas: Testada[];
    especial: "N" | "S"; // 'N' para não, 'S' para sim
    encravado: "N" | "S"; // 'N' para não, 'S' para sim
    tipo: string | null;
    tipoEspecial?: string | null;
    unidades: Unidade[] | null;
    enderecoId: number;
    logradouro: number | null;
    numero: number | null;
    complemento: string | null;
    bairro: string | null;
    apartamento: string | null;
    loteamento?: string | null;
    proprietarioId: number | null;
    proprietarioNome: string | null;
    proprietarioEndereco: string | null;
    proprietarioCpfCnpj: string | null;
    proprietarioTipo: "F" | "J"; // 'F' para pessoa física, 'J' para jurídica
  };
};

export async function postSaveAdversidadesGlobalService({ data }: Props) {
  try {
    const response = await fetch(url + "/adversidades/global/save", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(data),
    });
    const resp = await response.json();
    console.log(resp);

    if (resp.status !== 201) {
      throw new Error();
    }
    return resp;
  } catch (error) {
    console.log(error);

    throw new Error("Ocorreu um erro ao salvar.\n Tente novamente mais tarde");
  }
}
