import React, { Component, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Grid,
} from "@mui/material";
import mockPlantaImage from "../../../../../assets/img/mockPlanta.jpg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { Typography, Button } from "@mui/material";
import { Api } from "../../../../../services/Api";
import DoneIcon from "@mui/icons-material/Done";
import UploadComponent from "./componentsImage/uploadComponent";
import { RecipeReviewCard } from "./componentsImage/RecipeReviewCard";

export class TabImagens extends Component {
  constructor(props) {
    super(props);
    this.state = { imagemTemporaria: [], uploads: [] };

    // URL da imagem do AWS
    let imagemAWS;
    const fetch = async () => {
      try {
        imagemAWS = await Api.getImagensStreetView(props.id);
        const imagemTemporaria = imagemAWS[0].images.map((item) => item);

        this.setState({ imagemTemporaria }); // Armazena a imagem temporária no estado
      } catch (error) {
        console.error("Erro ao buscar a imagem do AWS:", error);
      }
    };
    fetch();
  }

  atualizarDadosImagens = () => {
    const { onUpdate } = this.props;
    if (onUpdate) {
      // Filtra as imagens que estão com selected como true
      const imagensSelecionadas = this.state.imagemTemporaria.filter(
        (imagem) => imagem.selected === true
      );
      const imagensInfo = imagensSelecionadas.map((imagem) => ({
        imagemId: imagem.imagemId, // Supondo que você tenha um campo imagemId
        nome: imagem.nome_imagem,
        angulo: imagem.angle,
        // Atualiza a extração para pegar o número até a barra '/'
        path: imagem.path,
      }));
      onUpdate(imagensInfo);
    }
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const novasImagens = files.map((file) => ({
      id: Date.now() + Math.random(),
      file: file,
      url: URL.createObjectURL(file),
    }));

    this.setState((prevState) => ({
      imagemTemporaria: novasImagens,
    }));
  };

  handleDeleteImagem = (imagemDeletada) => {
    this.setState((prevState) => {
      const imagemTemporariaAtualizada = prevState.imagemTemporaria.map(
        (imagem) =>
          imagem.nome_imagem === imagemDeletada.nome_imagem
            ? { ...imagem, selected: false }
            : imagem
      );
      return { imagemTemporaria: imagemTemporariaAtualizada };
    });
    this.atualizarDadosImagens();
  };

    salvarImagens = () => {
        const imagensSelecionadas = this.state.imagemTemporaria.filter(imagem => imagem.selected === true);

        console.log('Imagens para salvar:', imagensSelecionadas);
    };

  handleAddImage = (imagem) => {
    this.setState((prevState) => ({
      imagemTemporaria: prevState.imagemTemporaria.map((img) =>
        img.nome_imagem === imagem.nome_imagem
          ? { ...img, selected: true }
          : img
      ),
    }));
    this.atualizarDadosImagens();
  };

  render() {
    return (
      <div>
        <Grid container spacing={2} sx={{ paddingLeft: 0 }}>
          <UploadComponent loteId={this.props.id} />
          {this.state.imagemTemporaria.map((imagem) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={imagem.nome_imagem}
              sx={{ height: 150 }}
            >
              <RecipeReviewCard
                inviteStreetView={this.props.onClickImage}
                imagem={imagem}
                onDelete={() => this.handleDeleteImagem(imagem)}
                onAdd={() => this.handleAddImage(imagem)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}
