import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Button, Box, TextField, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Api } from "../../../../services/Api.js";
import '../ModalEdificacao/ModalEdificacao.css';

function ModalEdificacao({ onClose, inputs, atualizar }) {
    console.log(inputs);
    const optionsCobertura = {
        beiral: [
            { value: "Atípico", label: "Atípico" },
            { value: "Cobertura", label: "Cobertura" },
            { value: "Inexistente", label: "Inexistente" },
            { value: "Padrão", label: "Padrão" }
        ],
    };
    const [cobertura, setCobertura] = useState({
        id: Number(inputs.dados.id),
        andares: inputs.dados.andares,
        beiral: inputs.dados.beiral
    });

    const handleChange = (field, value) => {
        setCobertura(prevCobertura => ({
            ...prevCobertura,
            [field]: value
        }));
    };

    const submitForm = (e) => {
        e.preventDefault();
        // Chamar a função atualizar com os dados do formulário
        atualizar(cobertura);
        onClose()
    };

    return (
        <div className="modalEdificacao" onClick={onClose}>
            <div className="modalEdificacao-content" onClick={(e) => e.stopPropagation()} style={{ width: '600px' }}>
                <div className="modalEdificacao-close-bnt">
                    <Button
                        aria-label="Fechar"
                        style={{ padding: 0, minWidth: 0 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </div>
                <h2 className="modalEdificacao-titulo">Cobertura</h2>
                <Box display="flex" flexDirection="column" marginBottom={1}>
                    <Box display="flex" flexDirection="row">
                        <Box position='relative' sx={{ flexBasis: '50%' }}>
                            <Box sx={{ width: 20, height: 20, backgroundColor: inputs.cor, position: 'absolute', top: 18, right: 15 }} />
                            <TextField
                                label="Área Coberta"
                                value={inputs.dados.id}
                                sx={{ flexBasis: '50%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Box>

                        <TextField
                            label="Andares"
                            fullWidth
                            value={cobertura.andares}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '');
                                handleChange('andares', Number(value));
                            }}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />

                        <TextField
                            select
                            label="Beiral"
                            fullWidth
                            value={cobertura.beiral}
                            onChange={(e) => handleChange('beiral', e.target.value)}
                        >
                            {optionsCobertura.beiral.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Box>

                <div className="form-row">
                    <Button
                        style={{ width: "100%", marginTop: "10px" }}
                        variant="contained"
                        onClick={submitForm}
                    >
                        <FontAwesomeIcon
                            icon={faFloppyDisk}
                            style={{ marginRight: "0.5em" }}
                        />
                        Salvar
                    </Button>
                </div>

            </div>
        </div >

    );
};

export default ModalEdificacao;
