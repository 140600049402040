import { Divider } from "@mui/material"


export const Legenda = ({ current }) => {

    return (
        <>
            <div style={{ display: ((current === 'Quadra') && (sessionStorage.getItem('infoUsuarioRole') !== 'collector')) ? 'block' : 'none' }} >
                <div style={{ display: 'flex', padding: 5 }}>
                    <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Não Alocado</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: 'rgb(19,100,181)', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Alocado</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: 'rgb(41,143,33, 0.8)', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Coletado</h5>
                </div>
                <Divider style={{ marginBlock: 10, marginInline: 10 }} />
            </div>
            <div style={{ display: !(current === 'Quadra') ? 'block' : 'none' }} >
                <div style={{ display: 'flex', padding: 5 }}>
                    <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Não Coletado</h5>
                </div>
                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: 'rgb(41,143,33, 0.8)', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Coletado</h5>
                </div>
                <Divider style={{ marginBlock: 10, marginInline: 10 }} />
            </div>
        </>
    )
}