import React from 'react';
import { Box, MenuItem, TextField } from "@mui/material";

export class TabAreaCoberta extends React.Component {
    constructor(props) {
        super(props);
        this.inputRefs = React.createRef();
        this.coberturas = props.coberturas;
        this.state = {
            coberturas: this.inicializarCoberturas(props.coberturas, props.coberturasExistentes)
        };
    }

    inicializarCoberturas(coberturas, coberturasExistentes) {
        return coberturas.map((cobertura, index) => {
            const coberturaExistente = coberturasExistentes && coberturasExistentes[index];
            return {
                id: cobertura.dados?.id || '',
                andares: coberturaExistente?.andares || '',
                beiral: coberturaExistente?.beiral || ''
            };
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.save !== this.props.save && this.props.save) {
            this.props.onUpdate(this.state.coberturas)
        }
    }

    componentWillUnmount() {
        this.props.onUpdate(this.state.coberturas);
    }

    handleChange = (index, field, value) => {
        this.setState(prevState => {
            const newCoberturas = [...prevState.coberturas];
            newCoberturas[index] = {
                ...newCoberturas[index],
                [field]: value
            };
            return { coberturas: newCoberturas };
        });
    }

    render() {
        const { coberturas } = this.state;
        const optionsCobertura = {
            beiral: [
                { value: "Atípico", label: "Atípico" },
                { value: "Cobertura", label: "Cobertura" },
                { value: "Inexistente", label: "Inexistente" },
                { value: "Padrão", label: "Padrão" }
            ],
        };

        // Filtra coberturas para garantir que IDs únicos sejam renderizados
        const coberturasUnicas = Array.from(new Map(this.props.coberturas.map(item => [item.dados.id, item])).values());

        return (
            <>
                {coberturasUnicas.map((legenda, index) => (
                    <Box display="flex" flexDirection="column" key={index} marginBottom={1}>
                        <Box display="flex" flexDirection="row">
                            <Box position='relative' sx={{ flexBasis: '50%' }}>
                                <Box sx={{
                                    width: 20, height: 20, backgroundColor: legenda.color, position: 'absolute', top: 18, right: 25,
                                }} />
                                <TextField
                                    label="Área Coberta"
                                    value={legenda?.dados?.id || ''}
                                    sx={{ flexBasis: '40%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled
                                />
                            </Box>

                            <TextField
                                label="Andares"
                                fullWidth
                                value={coberturas[index]?.andares || ''}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9]/g, '');
                                    this.handleChange(index, 'andares', Number(value));
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                inputRef={el => this.inputRefs.current && (this.inputRefs.current[index * 2] = el)}
                            />

                            <TextField
                                select
                                label="Beiral"
                                fullWidth
                                value={coberturas[index]?.beiral || ''}
                                onChange={(e) => this.handleChange(index, 'beiral', e.target.value)}
                                inputRef={el => this.inputRefs.current && (this.inputRefs.current[index * 2 + 1] = el)}
                            >
                                {optionsCobertura.beiral.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>
                ))}
            </>
        );
    }
}