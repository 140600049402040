import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import './AlterarImovel.css';
import { Api } from "../../services/Api";
import { Fab, TextField } from "@mui/material";
import InfoImoveis from "./InfoImoveis/InfoImoveis";


function AlterarImovel() {

    const [numInscricao, setNumInscricao] = useState("");
    const [numInscricaoReduzida, setNumInscricaoReduzida] = useState("");
    const [searching, setSearching] = useState(false);
    const [resultReturned, setResultReturned] = useState(false);
    const [respostaApi, setRespostaApi] = useState("");

    async function submit() {

        if (numInscricao !== "" && numInscricao.length === 22 || numInscricaoReduzida !== "") {
            setSearching(true);
            const valor = {
                codigoImovel: numInscricaoReduzida || undefined,
                inscricaoImovel: numInscricao || undefined
            };

            const response = await Api.numeroInscricaoPost(valor);
            if (!response.error) {
                setRespostaApi(response);
                setResultReturned(true);
            } else {
                alert("O número solicitado não existe");
            }
            setSearching(false);
        }

    }

    //Enter Envia
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    const formataNumInscricao = (valor) => {
        valor = valor.replace(/\D/g, '');
        let valorFormatado = '';
        for (let i = 0; i < valor.length; i++) {
            if (i === 2 || i === 4 || i === 7 || i === 11 || i === 14) {
                valorFormatado += '.';
            }
            valorFormatado += valor[i];
            if (i >= 16) {
                break;
            }
        }
        return valorFormatado;
    }
    const numInscricaoChange = (event) => {
        const numero = event.target.value;
        const valorFormatado = formataNumInscricao(numero);
        setNumInscricao(valorFormatado);
    };
    return (
        <div className="main-container">
            {!resultReturned && (
                <>
                    <div className="container-imovel" style={{ padding: 10 }}>
                        <div className="conteudo-imovel">
                            <TextField
                                className="input-imovel"
                                label="N° de inscrição"
                                variant="outlined"
                                size="small"
                                value={numInscricao}
                                onChange={numInscricaoChange}
                                sx={{ margin: '5px', backgroundColor: "white" }}
                                onKeyPress={(event) => handleKeyPress(event)}
                                autoComplete="off"
                            />
                            <TextField
                                className="input-imovel"
                                label="N° de inscrição reduzido"
                                variant="outlined"
                                size="small"
                                value={numInscricaoReduzida}
                                onChange={(e) => setNumInscricaoReduzida(e.target.value)}
                                sx={{ margin: '5px' }}
                                onKeyPress={(event) => handleKeyPress(event)}
                                autoComplete="off"
                            />
                        </div>

                        <div className="conteudo-lista" style={{ height: '50px', padding: 0 }} >
                        </div>
                    </div>
                    <div className="barra-direita">
                        <Fab className="custom-button" onClick={submit}>
                            {searching ? (
                                <FontAwesomeIcon className="icon" icon={faSpinner} spin />
                            ) : (
                                <FontAwesomeIcon className="icon" icon={faMagnifyingGlass} />
                            )}
                        </Fab>
                    </div>

                </>
            )}
            {resultReturned && (
                <>
                    <InfoImoveis onBack={() => setResultReturned(false)} respostaApi={respostaApi} />
                </>
            )}
        </div>


    );
}
export default AlterarImovel;