import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Api } from "../../../services/Api.js";

import "./ModalAlterarLogradouro.css";

const ModalAlterarLogradouro = ({ onClose, informacoesLogradouro }) => {
  console.log(informacoesLogradouro.data.logradouro);
  const dadosRequest = informacoesLogradouro.data.logradouro
  const [selectedValueEsgoto, setSelectedValueEsgoto] = useState(dadosRequest.esgoto);
  const [selectedValueAgua, setSelectedValueAgua] = useState(dadosRequest.agua);
  const [selectedValueAguaPoco, setSelectedValueAguaPoco] = useState(dadosRequest.aguaPoco);
  const [selectedValueLuzEletrica, setSelectedValueLuzEletrica] = useState(dadosRequest.luzEletrica);
  const [selectedValueTelefone, setSelectedValueTelefone] = useState(dadosRequest.telefone);
  const [selectedValueColetaLixo, setSelectedValueColetaLixo] = useState(dadosRequest.coletaLixo);
  const [selectedValueFossa, setSelectedValueFossa] = useState(dadosRequest.fossa);


  async function testeAPI() {
    const data = await Api.logradourosCadastrados(informacoesLogradouro.id);
    console.log(informacoesLogradouro);
    console.log(data);
  }

  const onCloseClick = () => {
    onClose();
  };

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  async function requestApiAlterarLogradouro() {
    try {
      const infoAlterarLogradouro = {
        id: dadosRequest.id,
        nome: dadosRequest.nome,
        agua: selectedValueAgua,
        aguaPoco: selectedValueAguaPoco,
        coletaLixo: selectedValueColetaLixo,
        esgoto: selectedValueEsgoto,
        fossa: selectedValueFossa,
        luzEletrica: selectedValueLuzEletrica,
        telefone: selectedValueTelefone,
      };

      const data = await Api.addLogradouro(infoAlterarLogradouro);
      console.log(data);
      if (data.status !== undefined) {
        alert("Preencha os campos corretamente");
        onClose()
      }
    } catch (err) { }
  }

  return (
    <div className="modal-Eixo" onClick={onCloseClick}>
      <div className="modal-content" onClick={onModalClick}>
        <div className="modal-close-bnt">
          <Button
            aria-label="Fechar"
            style={{ padding: 0, minWidth: 0 }}
            onClick={onCloseClick}
          >
            <CloseIcon />
          </Button>
        </div>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        >
          Alterar Logradouro
        </h2>

        <form>
          <div className="form-group">
            <input
              className="ModalAlterarLogradouro-fixed"
              id="logradouro"
              value={dadosRequest.nome}
              required
              readOnly
            />
          </div>

          <div className="form-row-Logradouro">
            <div style={{ width: "100%" }}>
              <label
                htmlFor="selector"
                className="ModalAlterarLogradouro-label"
              >
                Luz elétrica:
              </label>
              <select
                className="ModalAlterarLogradouro-select"
                id="select"
                value={selectedValueLuzEletrica}
                onChange={(e) => setSelectedValueLuzEletrica(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="INEXISTENTE">Inexistente</option>
                <option value="NAO">Não</option>
                <option value="SIM">Sim</option>
              </select>
            </div>

            <div style={{ width: "100%" }}>
              <label
                htmlFor="selector"
                className="ModalAlterarLogradouro-label"
              >
                Telefone:
              </label>
              <select
                className="ModalAlterarLogradouro-select"
                id="select"
                value={selectedValueTelefone}
                onChange={(e) => setSelectedValueTelefone(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="INEXISTENTE">Inexistente</option>
                <option value="NAO">Não</option>
                <option value="SIM">Sim</option>
              </select>
            </div>
          </div>

          <div className="form-row-Logradouro">
            <div style={{ width: "180px" }}>
              <label
                htmlFor="selector"
                className="ModalAlterarLogradouro-label"
              >
                Água:
              </label>
              <select
                className="ModalAlterarLogradouro-select"
                id="select"
                value={selectedValueAgua}
                onChange={(e) => setSelectedValueAgua(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="INEXISTENTE">Inexistente</option>
                <option value="NAO">Não</option>
                <option value="SIM">Sim</option>
              </select>
            </div>

            <div style={{ width: "180px" }}>
              <label
                htmlFor="selector"
                className="ModalAlterarLogradouro-label"
              >
                Água de poço:
              </label>
              <select
                className="ModalAlterarLogradouro-select"
                id="select"
                value={selectedValueAguaPoco}
                onChange={(e) => setSelectedValueAguaPoco(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="INEXISTENTE">Inexistente</option>
                <option value="NAO">Não</option>
                <option value="SIM">Sim</option>
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="selector" className="ModalAlterarLogradouro-label">
              Fossa:
            </label>
            <select
              className="ModalAlterarLogradouro-select"
              id="select"
              value={selectedValueFossa}
              onChange={(e) => setSelectedValueFossa(e.target.value)}
              required
            >
              <option value="">Selecione:</option>
              <option value="INEXISTENTE">Inexistente</option>
              <option value="NAO">Não</option>
              <option value="SIM">Sim</option>
            </select>
          </div>

          <div className="form-row-Logradouro">
            <div style={{ width: "180px" }}>
              <label
                htmlFor="selector"
                className="ModalAlterarLogradouro-label"
              >
                Esgoto:
              </label>
              <select
                className="ModalAlterarLogradouro-select"
                id="select"
                value={selectedValueEsgoto}
                onChange={(e) => setSelectedValueEsgoto(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="INEXISTENTE">Inexistente</option>
                <option value="NAO">Não</option>
                <option value="SIM">Sim</option>
              </select>
            </div>

            <div style={{ width: "180px" }}>
              <label
                htmlFor="selector"
                className="ModalAlterarLogradouro-label"
              >
                Coleta de lixo:
              </label>
              <select
                className="ModalAlterarLogradouro-select"
                id="select"
                value={selectedValueColetaLixo}
                onChange={(e) => setSelectedValueColetaLixo(e.target.value)}
                required
              >
                <option value="">Selecione:</option>
                <option value="INEXISTENTE">Inexistente</option>
                <option value="NAO">Não</option>
                <option value="SIM">Sim</option>
              </select>
            </div>
          </div>

          <div className="form-row-Logradouro" style={{ marginTop: "50px" }}>
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              variant="contained"
              onClick={() => requestApiAlterarLogradouro()}
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "0.5em" }}
              />
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalAlterarLogradouro;
