import styled from "styled-components";
import { Button as Btn, Card as CardMui, TableCell } from "@mui/material";

export const Card = styled(CardMui).attrs({
  variant: "outlined",
  style: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})``;

export const Button = styled(Btn).attrs({
  sx: {
    height: "35px",
    border: "1px solid #1976D2",
  },
})``;

export const ContainerOpcoes = styled(TableCell).attrs({
  align: "left",
  sx: {
    width: "90%",
  },
})``;
