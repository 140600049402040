import React, { Component } from 'react';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { CardContent, Typography } from '@mui/material';

export class ManegerOpenStreet extends Component {
    constructor(props) {
        super(props);
        this.angle = null
        this.baseUrl = props?.url || false; // Recebendo a URL como parâmetro
        this.pSRef = React.createRef(); // Adicionando referência para o ReactPhotoSphereViewer
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {

            this.angle = this.props.url.angle;
            const encodedUrl = 'https://cdn.pannellum.org/2.5/pannellum.htm#panorama=' + encodeURIComponent(this.props.url.url) + `&yaw=${this.angle}`;
            this.baseUrl = encodedUrl;
            this.forceUpdate();
        }
    }

    render() {
        return (<>{!this.baseUrl ?
            <CardContent sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px dashed #ccc',
                borderRadius: '4px',
                boxSizing: 'border-box',
                '&:last-child': { paddingBottom: '16px' },
                '&:hover': {
                    cursor: 'pointer', // Muda o cursor para indicar interatividade
                    backgroundColor: '#f5f5f5', // Adiciona um cinza claro como hover
                },
            }}>
                <ImageSearchIcon sx={{ fontSize: 60, color: '#999', mb: 2 }} />
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                    Selecione uma imagem!
                </Typography>
            </CardContent>
            :
            <iframe key={this.baseUrl} width="100%" height="100%" allowfullscreen style={{ borderStyle: "none" }} src={`${this.baseUrl}&autoLoad=true&yaw=${this.angle}`}></iframe>
        }
        </>
        );
    }
}
