export async function downloadFile(
  response: Response,
  filename: string
): Promise<void> {
  const blob = await response.blob();
  const urlFile = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = urlFile;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(urlFile);
}
