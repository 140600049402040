export const fnUnidadeImobiliaria = (request) => {
    try {
        return request.map((dado) => {
            const cobertura = dado.areaCoberta.map((item) => ({
                id: item.id || null,
                beiral: item.beiral || null,
                andares: item.andares || null
            }));

            return {
                unidadeImobiliaria: {
                    id: dado.unidadeImobiliaria.id || 0,
                    areaEdicula: Number(dado.unidadeImobiliaria.areaEdicula) || 0,
                    areaConstrucao: Number(dado.unidadeImobiliaria.areaConstrucao) || 0,
                    conservacao: dado.unidadeImobiliaria.conservacao || null,
                    estrutura: dado.unidadeImobiliaria.estrutura || null,
                    cobertura: dado.unidadeImobiliaria.cobertura || null,
                    instSanitaria: dado.unidadeImobiliaria.instSanitaria1 || null,
                    instEletrica: dado.unidadeImobiliaria.instEletrica || null,
                    categoria: dado.unidadeImobiliaria.categoria || null,
                    revestimento: dado.unidadeImobiliaria.revestimento || null,
                    fachada: dado.unidadeImobiliaria.fachada || null,
                    situacao: dado.unidadeImobiliaria.situacao || null,
                    forro: dado.unidadeImobiliaria.forro || null,
                    parede: dado.unidadeImobiliaria.parede || null,
                    esquadrias: dado.unidadeImobiliaria.esquadrias || null,
                    acesso: dado.unidadeImobiliaria.acesso || null,
                    piso: dado.unidadeImobiliaria.piso || null,
                },
                areaCoberta: cobertura,
            };
        });
    } catch (error) {
        console.error("Error in fnUnidadeImobiliaria:", error);
        return [];
    }
}

export const fnArrayUnidades = (request) => {
    try {
        return request.map((item) => ({
            ...item.unidadeImobiliaria,
            utilizacao: item.unidadeImobiliaria.utilizacao || '',
            tipo: item.unidadeImobiliaria.tipo || '',
            alinhamento: item.unidadeImobiliaria.alinhamento || '',
            posicao: item.unidadeImobiliaria.posicao || '',
            classificacao: item.unidadeImobiliaria.classificacao || '',
            piso: item.unidadeImobiliaria.piso || '',
            pontoDeInteresse: item.unidadeImobiliaria.pontoDeInteresse || false,
            idInteresse: item?.pontoInteresse?.id || null,
            tipoInteresse: item?.pontoInteresse?.tipo || null,
            nomeInteresse: item?.pontoInteresse?.nome || null
        }));
    } catch (error) {
        console.error("Error in fnArrayUnidades:", error);
        return [];
    }
}

export const fnDadosLotes = (request, unidades) => {
    let enderecoId
    if (request.unidades.length > 0) {
        enderecoId = request.unidades[0].endereco.id
    } else { enderecoId = request.endereco.id }

    try {
        return {
            id: request.lote.id,
            enderecoId: enderecoId,
            lote: {
                id: request.lote.id || 0,
                areaTerreno: request.lote.areaTerreno || 0,
                areaTerrenoIsento: request.lote.areaTerrenoIsento || 0,
                areaConstrucao: request.lote.areaConstrucao || 0,
                fracaoIdeal: null,
                topologia: request.lote.topologia || null,
                pedologia: request.lote.pedologia || null,
                nivelacao: request.lote.nivelacao || null,
                tipo: request.lote.tipo || null,
                segmento: request.lote.segmento || null,
                lado: request.lote.lado || null,
                utilizacao: request.lote.utilizacao || null,
                patrimonio: request.lote.patrimonio || null,
                finalidade: request.lote.finalidade || null,
                frentes: request.lote.frentes || null,
                arvore: request.lote.arvore || null,
                formato: request.lote.formato || null,
                predial: request.lote.predial === "s" ? "Sim" : "Não",
                profundidade: request.lote.profundidade || 0,
                vago: request.lote.vago || null,
                conservacao: request.lote.conservacao || null
            },
            unidades: unidades?.unidadeImobiliaria || null,
            pois: [{ id: 0, unidadeId: 0, tipo: "", nome: "" }]
        };
    } catch (error) {
        console.error("Error in fnDadosLotes:", error);
        return null;
    }
}
