import React from "react";
import {
  Container,
  Card,
  Title,
  Divider,
  ButtonContainer,
  Button,
  Box,
  RowContainer,
} from "./styles";

import { FormLabel, Radio, RadioGroup, FormControlLabel } from "@mui/material";

import {
  InputComponent,
  SelectComponent,
} from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Components/Inputs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPlus } from "@fortawesome/free-solid-svg-icons";

import { AdversidadesGlobalResponse } from "../../../../services/getAdversidadesGlobalService";
import { InputContext } from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Context/ContextInputs";
import { MapContextAdv } from "../../../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Context/FuncoesMapa";

import {
  useForm,
  Controller,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TestadaLabel } from "../TestadaLabel";

import { postSaveAdversidadesGlobalService } from "../../../../services/postSaveAdversidadesGlobalService";
import { Unidades } from "../Unidades";
import { IEndereco, IProprietario } from "../../../../services/types";
import { toast } from "react-toastify";
import { ContextMap } from "../../../../ContextMap/ContextMap";
import { validationSchema } from "../../../../hooks/validationSchema";
import { postSearchXYService } from "../../../../services/postSearchXYService";

type Props = {
  title: string;
  numReduzido?: number | null;
  adversidade: AdversidadesGlobalResponse;
  tipoAdversidade?: string;
  lotecod_novo?: number | null;
};

export interface IUnidade {
  areaconstrucao: number;
  numreduzido: number;
  unidadecod: number;
  unidadecod_novo?: number;
  lotecod?: number;
  lotecod_novo?: number | string | null;
  proprietarioid: number;
  proprietarionome: string;
  proprietariocpfcnpj: string;
  proprietariotipo: "F" | "J";
  enderecologradouro: string;
  enderecologradouro_novo: string;
  endereconumero: number | null;
  enderecocomplemento: string;
  enderecobairro: string | null;
  coberturas:
    | {
        id: number;
      }[]
    | null;
  enderecologradourocod: number | null;
}
interface ConsultaLoteResponse {
  LoteAntigoId: number;
  unidades: IUnidade[];
  qtdUnidades: number | null;
  loteCod: number;
  distritoCod: number | null;
  quadraCod: number;
  setorCod: number;
  endereco: IEndereco;
  proprietario: IProprietario;
  novo_setor: number | null;
  nova_quadra: number | null;
  novo_lote: number | null;
}
interface FormData extends ConsultaLoteResponse {
  numCodigo: string;
  valueRadioGroupTestada: string;
  testadas: {
    eixoProximo: {
      dist: number;
      eixoId: string;
      logradouronome: string;
    };

    id: number;
    length: number;
  }[];
  coberturas: any[];
  encravado: string | null;
  areaEspecial: string | null;
  lotecod_novo?: number | string | null;
}

export function InitialTable({
  title,
  numReduzido,
  adversidade,
  tipoAdversidade,
  lotecod_novo,
}: Props) {
  const [showEnderecoLote, setShowEnderecoLote] = React.useState(false);
  const methods = useForm<FormData>({
    defaultValues: {
      qtdUnidades: null,
      unidades: [],
      distritoCod: null,
      numCodigo: numReduzido ? String(numReduzido) : "",
    },
    //@ts-ignore
    resolver: yupResolver(validationSchema.saveAdversidades),
    context: { showEnderecoLote },
  });
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "unidades",
  });
  const watchUnidades = watch("unidades");

  const onError = (errors: any) => {
    console.log("Erros de validação:", errors); // Captura e exibe os erros
  };

  const [edit, setEdit] = React.useState(numReduzido ? true : false);

  const { setTestadasIds } = React.useContext(InputContext);
  const { setTestadas, setCoberturas } = React.useContext(MapContextAdv);
  const { alteraOpcao } = React.useContext(ContextMap);

  async function handleSearchLote() {
    try {
      if (getValues().numCodigo) {
        setError("numCodigo", { message: "" });
        const resp = await postSearchXYService({
          principal: Number(getValues().numCodigo),
          reduzidos:
            adversidade.lotes.length > 0
              ? adversidade.lotes.map((lote) => lote.reduzido)
              : [Number(getValues().numCodigo)],
        });

        if (resp.status === 200) {
          setEdit(true);
          setValue("LoteAntigoId", resp.data.LoteAntigoId);
          setValue("unidades", resp.data.unidades);
          if (adversidade.testadas.length == 0) setValue("encravado", "Sim");
          setValue("qtdUnidades", resp.data.qtdUnidades);
          setValue("loteCod", resp.data.loteCod);
          setValue("distritoCod", resp.data.distritoCod);
          setValue("quadraCod", resp.data.quadraCod);
          setValue("setorCod", resp.data.setorCod);
          setValue("endereco", resp.data.endereco);
          setValue("proprietario", resp.data.proprietario);
          setValue("nova_quadra", adversidade.adversidade.nova_quadra);
          setValue("novo_lote", adversidade.adversidade.novo_lote);
          setValue("novo_setor", adversidade.adversidade.novo_setor);
          const logradouroApi = adversidade.testadas.map((logNome) => logNome);
          setTestadas(logradouroApi);
          setValue("testadas", logradouroApi);
          setValue("coberturas", adversidade.coberturas);
          setTestadasIds(adversidade.testadas.map((testada) => testada.id));
          setCoberturas(adversidade.coberturas);
          if (resp.data.qtdUnidades == 0) {
            setShowEnderecoLote(true);
          } else {
            setShowEnderecoLote(false);
          }
          const testadaCorrespondente = adversidade.testadas.find(
            (testada) =>
              testada.eixoProximo.logradouronome ===
              resp.data.endereco.logradouro
          );
          if (testadaCorrespondente) {
            setValue("valueRadioGroupTestada", `${testadaCorrespondente.id}`);
          } else {
            setValue("valueRadioGroupTestada", "");
          }
          setIsSelected(false);
          if (tipoAdversidade === "R") {
            setValue("lotecod_novo", lotecod_novo);
          }
        } else {
          throw new Error("Id/Reduzido não encontrado");
        }
      } else {
        setError("numCodigo", { message: "Informe o numero" });
      }
    } catch (error: any) {
      window.alert(`Erro ao consultar lote:\n${error.message}`);
    }
  }

  const handleAddUnidade = () => {
    const formValues = getValues();
    const novaUnidade: IUnidade = {
      areaconstrucao: 0,
      numreduzido: Number(formValues.numCodigo),
      unidadecod: 0,
      proprietarioid: formValues.proprietario?.id || 0,
      proprietarionome: formValues.proprietario?.nome || "",
      proprietariocpfcnpj: formValues.proprietario?.cpfCnpj || "",
      proprietariotipo: formValues.proprietario?.tipo || "",
      enderecologradouro: formValues.endereco?.logradouro || "",
      enderecologradouro_novo: formValues.endereco?.logradouro_novo || "",
      endereconumero: Number(formValues.endereco?.numero) || null,
      enderecocomplemento: formValues.endereco?.complemento || "",
      enderecobairro: formValues.endereco?.bairro || null,
      lotecod: 0,

      coberturas: null,
      enderecologradourocod: null,
    };

    append(novaUnidade);
    setValue("qtdUnidades", fields.length + 1);
    setShowEnderecoLote(false);
  };
  const handleRemoveUnidade = (index: number) => {
    remove(index);
    setValue("qtdUnidades", fields.length - 1);
    if (fields.length - 1 === 0) {
      setShowEnderecoLote(true);
    }
  };

  async function handleSave(data: FormData) {
    if (data.endereco.logradouro_novo) {
      data.endereco.logradouro = data.endereco.logradouro_novo;
    }
    delete (data.endereco as any).logradouro_novo;
    data.unidades.map((unidade) => {
      if (unidade.enderecologradouro_novo) {
        unidade.enderecologradouro = unidade.enderecologradouro_novo;
      }
      delete (unidade as any).enderecologradouro_novo;
    });
    if (data.coberturas.length > 0 && data.unidades.length == 0) {
      toast.error(
        "Este imóvel tem em seu cadastro ocupação (não edificada) será preciso criar uma unidade para associar a(s) cobertura(s) "
      );
    } else {
      try {
        console.log("FORMZINHO", data);
        console.log("ADVERSIDADE", adversidade);

        const testadas = data.testadas.map((testada) => ({
          //@ts-ignore
          eixoId: Number(testada.eixoProximo.eixoid),
          testadaId: testada.id,
        }));

        const unidades = data.unidades.map((unidade) => {
          const logradouroId = adversidade.logradouros.filter(
            (logradouro) => logradouro.nome === unidade.enderecologradouro
          )[0]?.id;

          return {
            unidadeId: unidade.numreduzido,
            coberturas: unidade.coberturas
              ? unidade.coberturas.map((cobertura) => String(cobertura.id))
              : null,
            benfeitorias: adversidade.benfeitorias,
            logradouro: Number(logradouroId) || null, // Convertendo para number
            numero: Number(unidade.endereconumero),
            complemento: unidade.enderecocomplemento,
            bairro: unidade.enderecobairro,
            proprietarioId: unidade.proprietarioid,
            proprietarioNome: unidade.proprietarionome,
            proprietarioCpfCnpj: unidade.proprietariocpfcnpj,
            proprietarioTipo: unidade.proprietariotipo,
            unidadeCod: Number(unidade.unidadecod_novo),
          };
        });

        const response = await toast.promise(
          postSaveAdversidadesGlobalService({
            //@ts-ignore
            data: {
              adversidadeId: adversidade.adversidadeId,
              loteId: data.LoteAntigoId,
              setorCod: adversidade.adversidade.novo_setor
                ? adversidade.adversidade.novo_setor
                : data.setorCod,
              quadraCod: adversidade.adversidade.nova_quadra
                ? adversidade.adversidade.nova_quadra
                : data.quadraCod,
              loteCod: adversidade.adversidade.novo_lote
                ? adversidade.adversidade.novo_lote
                : data.loteCod,
              testadaPrincipal: Number(data.valueRadioGroupTestada),
              testadas: testadas,
              especial: data.areaEspecial === "N" ? data.areaEspecial : "S",
              encravado: data.testadas === null ? "S" : "N",
              tipo: data.qtdUnidades === 0 ? "vazio" : "normal",
              tipoEspecial:
                data.areaEspecial === "N" ? null : data.areaEspecial,
              unidades: unidades && unidades.length > 0 ? unidades : [], // Verifica se unidades não está vazio
              enderecoId: data.endereco.id,
              logradouro:
                adversidade.logradouros.filter(
                  (logradouro) => logradouro.nome === data.endereco.logradouro
                )[0]?.id || 0, // Certifique-se de que logradouroCod é do tipo correto
              numero: Number(data.endereco.numero),
              complemento: data.endereco.complemento,
              bairro: data.endereco.bairro,
              apartamento: data.endereco.apartamento,
              proprietarioId: data.proprietario.id,
              proprietarioNome: data.proprietario.nome,
              proprietarioEndereco: data.proprietario.endereco,
              proprietarioCpfCnpj: data.proprietario.cpfCnpj,
              proprietarioTipo: data.proprietario.tipo,
            },
          }),
          {
            pending: "Salvando adversidade...",
            success: "Adversidade resolvida com sucesso!",
            error: {
              render({ data }) {
                return `${
                  //@ts-ignore
                  data.message
                }`;
              },
            },
          }
        );
        if (response) {
          //voltar
          await alteraOpcao("");
          alteraOpcao("gerenciarAdversidades");
        }
      } catch (error: any) {
        console.log("🚀 ~ handleSave ~ error:", error);
      }
    }
  }

  const [isSelected, setIsSelected] = React.useState(false);

  const handleChangeAssociarCoberturas = () => {
    const novoEstado = !isSelected;

    setIsSelected(novoEstado);

    const unidades = getValues("unidades");

    unidades.forEach((_, index) => {
      if (novoEstado) {
        // Associar todas as coberturas
        const todasCoberturas = adversidade.coberturas.map((cobertura) => ({
          id: cobertura.id,
        }));
        //@ts-ignore
        setValue(`unidades.${index}.coberturas`, todasCoberturas);
      } else {
        // Desassociar todas as coberturas
        //@ts-ignore
        setValue(`unidades.${index}.coberturas`, []);
      }
    });
  };

  React.useEffect(() => {
    if (numReduzido && numReduzido > 0) {
      setValue("numCodigo", String(numReduzido));
      handleSearchLote();
    }
  }, [numReduzido]);

  React.useEffect(() => {
    const checkNovoLogradouro = () => {
      const logradouroAtual = getValues().endereco?.logradouro;
      if (logradouroAtual && adversidade.testadas) {
        const testadaCorrespondente = adversidade.testadas.find(
          (testada: any) =>
            testada.eixoProximo.logradouronome === logradouroAtual
        );
        if (testadaCorrespondente) {
          setValue(`endereco.logradouro_novo`, logradouroAtual);
        }
      }
    };
    checkNovoLogradouro();
  }, [getValues().endereco?.logradouro]);

  const optionsAreaEspecial = [
    { value: "N", label: "Não" },
    { value: "AV", label: "Área verde" },
    { value: "AR", label: "Área remanescente" },
    { value: "AI", label: "Área institucional" },
    { value: "APP", label: "Área de preservação permanente" },
  ];

  return (
    <FormProvider {...methods}>
      <Container>
        <Card>
          <Title>{title}</Title>

          <Divider />

          <div style={{ width: "100%" }}>
            <Box>
              <Controller
                control={control}
                name='numCodigo'
                render={({ field }) => (
                  <InputComponent
                    label='Id/Reduzido'
                    id='numCodigo'
                    {...field}
                    value={field.value || ""}
                    required={edit}
                    readOnly={edit}
                    errors={errors.numCodigo?.message}
                  />
                )}
              />

              <h5>Inscrição atual:</h5>

              <RowContainer>
                <Controller
                  control={control}
                  name='distritoCod'
                  render={({ field }) => (
                    <InputComponent
                      label='Distrito'
                      id='distritoCod'
                      {...field}
                      value={field.value || ""}
                      required
                      readOnly
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='setorCod'
                  render={({ field }) => (
                    <InputComponent
                      label='Setor'
                      id='setorCod'
                      {...field}
                      required
                      readOnly
                      value={field.value || ""}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='quadraCod'
                  render={({ field }) => (
                    <InputComponent
                      label='Quadra'
                      id='setorCod'
                      {...field}
                      required
                      readOnly
                      value={field.value || ""}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='loteCod'
                  render={({ field }) => (
                    <InputComponent
                      label='Lote'
                      id='loteCod'
                      {...field}
                      required
                      readOnly
                      value={field.value || ""}
                    />
                  )}
                />
                {tipoAdversidade !== adversidade.adversidade.tipo && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 10,
                      width: "100%",
                      height: 60,
                    }}
                  >
                    <label style={{ display: "block", marginBottom: 5 }}>
                      Consulta Lote:
                    </label>
                    <Button
                      sx={{
                        height: "28px",
                        padding: "5px",
                        minWidth: "100px",
                        verticalAlign: "middle",
                      }}
                      onClick={edit ? () => setEdit(false) : handleSearchLote}
                    >
                      {edit ? "Trocar" : "Buscar"}
                    </Button>
                  </div>
                )}
              </RowContainer>

              {getValues("novo_lote") && getValues("loteCod") !== null && (
                <>
                  <h5>Inscrição nova:</h5>
                  <RowContainer>
                    <Controller
                      control={control}
                      name='distritoCod'
                      render={({ field }) => (
                        <InputComponent
                          label='Distrito'
                          id='distritoCod'
                          {...field}
                          value={field.value || ""}
                          required
                          readOnly
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='novo_setor'
                      render={({ field }) => (
                        <InputComponent
                          label='Setor'
                          id='novo_setor'
                          {...field}
                          required
                          readOnly
                          value={field.value || ""}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='nova_quadra'
                      render={({ field }) => (
                        <InputComponent
                          label='Quadra'
                          id='nova_quadra'
                          {...field}
                          required
                          readOnly
                          value={field.value || ""}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='novo_lote'
                      render={({ field }) => (
                        <InputComponent
                          label='Lote'
                          id='novo_lote'
                          {...field}
                          required
                          readOnly
                          value={field.value || ""}
                        />
                      )}
                    />
                  </RowContainer>
                </>
              )}

              {adversidade.testadas.length > 0 ? (
                <>
                  <FormLabel
                    id='demo-controlled-radio-buttons-group'
                    style={{ marginLeft: "10px" }}
                  >
                    Testada Principal:
                  </FormLabel>

                  <Controller
                    control={control}
                    name='valueRadioGroupTestada'
                    render={({ field }) => (
                      <div>
                        <RadioGroup
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => field.onChange(e.target.value)}
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {adversidade.testadas.map((testada, index) => (
                            <FormControlLabel
                              key={`${testada.id}${index}`}
                              value={`${testada.id}`}
                              control={<Radio />}
                              label={
                                <TestadaLabel
                                  indexColor={index}
                                  name={testada.eixoProximo.logradouronome}
                                />
                              }
                              style={{ marginRight: "20px" }}
                            />
                          ))}
                        </RadioGroup>
                        {errors.valueRadioGroupTestada && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              marginTop: "5px",
                            }}
                          >
                            {errors.valueRadioGroupTestada.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                  {getValues().coberturas &&
                    getValues().coberturas.length > 0 && (
                      <FormControlLabel
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        control={
                          <Radio
                            checked={isSelected}
                            onClick={handleChangeAssociarCoberturas}
                          />
                        }
                        label='Associar todas as unidades a todas as coberturas?'
                      />
                    )}
                </>
              ) : (
                <Controller
                  control={control}
                  name='encravado'
                  render={({ field }) => (
                    <InputComponent
                      label='Encravado'
                      id='encravado'
                      {...field}
                      readOnly
                      required
                      value={field.value || ""}
                      errors={errors.encravado?.message}
                    />
                  )}
                />
              )}

              {showEnderecoLote && (
                <>
                  <h5>Endereço do Lote:</h5>

                  <RowContainer>
                    <Controller
                      control={control}
                      name='endereco.logradouro'
                      render={({ field }) => (
                        <InputComponent
                          label='Logradouro Atual'
                          id='endereco.logradouro'
                          {...field}
                          readOnly
                          required
                          value={field.value || ""}
                          errors={errors.endereco?.logradouro?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='endereco.logradouro_novo'
                      render={({ field }) => (
                        <SelectComponent
                          label='Novo logradouro para cadastro'
                          id='endereco.logradouro_novo'
                          {...field}
                          options={adversidade.testadas.map(
                            (testada, index) => ({
                              value: testada.eixoProximo.logradouronome,
                              label: testada.eixoProximo.logradouronome,
                            })
                          )}
                          value={field.value || ""}
                          errors={errors.endereco?.logradouro_novo?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='endereco.numero'
                      render={({ field }) => (
                        <InputComponent
                          label='Número'
                          id='endereco.numero'
                          {...field}
                          value={field.value || ""}
                          errors={errors.endereco?.numero?.message}
                        />
                      )}
                    />
                  </RowContainer>

                  <RowContainer>
                    <Controller
                      control={control}
                      name='endereco.complemento'
                      render={({ field }) => (
                        <InputComponent
                          label='Complemento'
                          id='endereco.complemento'
                          {...field}
                          value={field.value || ""}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='endereco.bairro'
                      render={({ field }) => (
                        <InputComponent
                          label='Bairro'
                          id='endereco.bairro'
                          {...field}
                          value={field.value || ""}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='endereco.apartamento'
                      render={({ field }) => (
                        <InputComponent
                          label='Apartamento'
                          id='endereco.apartamento'
                          {...field}
                          value={field.value || ""}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='endereco.loteamento'
                      render={({ field }) => (
                        <InputComponent
                          label='Loteamento'
                          id='endereco.loteamento'
                          {...field}
                          value={field.value || ""}
                        />
                      )}
                    />
                  </RowContainer>
                  <Divider />
                  <h5 className='Unidades-margin-h5'>Dados do Proprietário</h5>
                  <RowContainer>
                    <Controller
                      control={control}
                      name='proprietario.tipo'
                      render={({ field }) => (
                        <SelectComponent
                          label='Tipo de Proprietário'
                          id='proprietariotipo'
                          {...field}
                          options={[
                            { value: "F", label: "Físico" },
                            { value: "J", label: "Jurídico" },
                          ]}
                          value={field.value || ""}
                          errors={errors.proprietario?.tipo?.message}
                          disabled
                          required
                        />
                      )}
                    />
                    <Controller
                      name='proprietario.cpfCnpj'
                      control={control}
                      render={({ field }) => (
                        <InputComponent
                          label='CPF/CNPJ'
                          id='proprietariocpfcnpj'
                          {...field}
                          errors={errors.proprietario?.cpfCnpj?.message}
                          readOnly={!!field.value}
                          required={!!field.value}
                        />
                      )}
                    />
                    <Controller
                      name='proprietario.nome'
                      control={control}
                      render={({ field }) => (
                        <InputComponent
                          label='Nome'
                          id='proprietarionome'
                          {...field}
                          errors={errors.proprietario?.nome?.message}
                          readOnly={!!field.value}
                          required={!!field.value}
                        />
                      )}
                    />
                  </RowContainer>
                </>
              )}
              <Divider />
              {getValues().distritoCod !== null && (
                <RowContainer>
                  <Controller
                    control={control}
                    name='areaEspecial'
                    render={({ field }) => (
                      <SelectComponent
                        label='Área Especial'
                        id='areaEspecial'
                        {...field}
                        options={optionsAreaEspecial}
                        value={field.value || ""}
                        errors={errors.areaEspecial?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='qtdUnidades'
                    render={({ field }) => (
                      <InputComponent
                        label='Nº de Unidade(s)'
                        id='qtdUnidades'
                        {...field}
                        readOnly
                        required
                        value={field.value || 0}
                        errors={errors.qtdUnidades?.message}
                      />
                    )}
                  />
                  {watchUnidades.length == 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 10,
                        width: "100%",
                        height: 60,
                      }}
                    >
                      <label
                        htmlFor='codigo'
                        style={{ display: "block", marginBottom: 5 }}
                      >
                        Adicionar unidade:
                      </label>
                      <Button
                        sx={{
                          height: "28px",
                          padding: "5px",
                          minWidth: "100px",
                          verticalAlign: "middle",
                        }}
                        onClick={handleAddUnidade}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Unidade
                      </Button>
                    </div>
                  )}
                </RowContainer>
              )}
            </Box>
          </div>

          {getValues().unidades && getValues().unidades.length > 0 && (
            <Unidades
              onRemove={handleRemoveUnidade}
              tipoAdversidade={tipoAdversidade}
            />
          )}

          <ButtonContainer>
            <Button
              onClick={handleSubmit(handleSave, onError)}
              disabled={getValues().distritoCod === null ? true : false}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Salvar
            </Button>
          </ButtonContainer>
        </Card>
      </Container>
    </FormProvider>
  );
}
