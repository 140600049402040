import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";

import { Api } from "../../services/Api.js";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./GerenciarAdversidades.css";
import { ContextMap } from "../../ContextMap/ContextMap";
import { ContextAdv } from "./ContextAdv.jsx";

function GerenciarAdversidades() {
  const [filteredData, setFilteredData] = useState([]);
  const [totalizador, setTotalizador] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [selectedAdversidade, setSelectedAdversidade] = useState("");
  const [selectedTipoUsuario, setSelectedTipoUsuario] = useState("");
  const { alteraOpcao } = useContext(ContextMap);
  const { setIdAdv } = useContext(ContextAdv);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api.getAdversidades();
        setTotalizador(data.data);
        // Filtrar os dados com base em selectedAdversidade e selectedTipoUsuario
        let filteredData = data.data.adversidades.filter(
          (item) => item.flag === 0
        );

        if (selectedAdversidade) {
          filteredData = filteredData.filter(
            (item) => item.tipo === selectedAdversidade
          );
        }

        // Função para filtrar com base no primeiro caractere da string "name"
        if (selectedTipoUsuario) {
          filteredData = filteredData.filter(
            (objeto) => objeto.name.charAt(0) === selectedTipoUsuario
          );
        }

        setFilteredData(filteredData);
      } catch (error) {
        console.log("Deu erro:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedAdversidade, selectedTipoUsuario]);

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleClickAdversidade = (adversidades) => {
    setIdAdv(adversidades.id);
    alteraOpcao("");
    alteraOpcao("adversidades");
    // const tipo = adversidades.tipo;
    // switch (tipo) {
    //   case "R":
    //     opcaoClicada("remembramento");
    //     break;
    //   case "End":
    //     opcaoClicada("advEndereco");
    //     break;
    //   case "I":
    //     opcaoClicada("adversidades");
    //     break;
    //   case "C":
    //     opcaoClicada("mesmoLote");
    //     break;
    //   default:
    // }
  };

  //TABELA IDENTIFCAÇÃO ADVERSIDADES
  //   R: Remembramento
  //   C: Lote com múltiplas unidades e coberturas
  //   I: Lotes não identificados
  //   End: Endereços incompatíveis com o logradouro
  function createData(name, numeros) {
    return { name, numeros };
  }
  //row table total adversidades
  const rows = [
    createData("Remembramento", totalizador.TR),
    createData("Lote com múltiplas unidades e coberturas", totalizador.TC),
    createData("Lotes não identificados", totalizador.TXY),
    createData("Endereços incompatíveis com o logradouro", totalizador.TEnd),
  ];
  const totalAdversidades =
    totalizador.TR + totalizador.TC + totalizador.TXY + totalizador.TEnd;
  //row table total adversidades resolvidas
  const rowsSecond = [
    createData("Remembramento", totalizador.TRResolvidas),
    createData(
      "Lote com múltiplas unidades e coberturas",
      totalizador.TCResolvidas
    ),
    createData("Lotes não identificados", totalizador.TXYResolvidas),
    createData(
      "Endereços incompatíveis com o logradouro",
      totalizador.TEndResolvidas
    ),
  ];
  const totalResolvido = totalizador.totalResolvidas;

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className='GerenciarAdversidades-lista-container'>
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <TableContainer sx={{ maxWidth: 700 }} component={Paper}>
              <Table sx={{ maxWidth: 700 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontSize: 11, padding: 7, fontWeight: "bold" }}
                    >
                      TOTAL DE ADVERSIDADES
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 11, padding: 7, fontWeight: "bold" }}
                      align='right'
                    >
                      {isLoading ? (
                        <Skeleton animation='wave' width={20} height={20} />
                      ) : (
                        totalAdversidades
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        component='th'
                        scope='row'
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        align='right'
                      >
                        {isLoading ? (
                          <Skeleton animation='wave' width={20} height={20} />
                        ) : (
                          row.numeros
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer sx={{ maxWidth: 700 }} component={Paper}>
              <Table sx={{ maxWidth: 700 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontSize: 11, padding: 7, fontWeight: "bold" }}
                    >
                      TOTAL DE ADVERSIDADES RESOLVIDAS
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 11, padding: 7, fontWeight: "bold" }}
                      align='right'
                    >
                      {isLoading ? (
                        <Skeleton animation='wave' width={20} height={20} />
                      ) : (
                        totalResolvido
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSecond.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        component='th'
                        scope='row'
                      >
                        {row.name}
                      </TableCell>

                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        align='right'
                      >
                        {isLoading ? (
                          <Skeleton animation='wave' width={20} height={20} />
                        ) : (
                          row.numeros
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "58vh",
          }}
        >
          <div className='GerenciarAdversidades-form-row'>
            <div className='GerenciarAdversidades-form-group'>
              <FormControl
                sx={{ width: "100%", backgroundColor: "white" }}
                size='small'
              >
                <InputLabel>Adversidades</InputLabel>
                <Select
                  label='Adversidades'
                  value={selectedAdversidade}
                  onChange={(event) =>
                    setSelectedAdversidade(event.target.value)
                  }
                  testid='selectAdversidades'
                >
                  <MenuItem value=''>
                    <em>Todas as adversidades</em>
                  </MenuItem>

                  <MenuItem value='R'>(R) Remembramento</MenuItem>

                  <MenuItem value='C'>
                    (C) Identificação de unidades imobiliárias dentro do mesmo
                    lote
                  </MenuItem>

                  <MenuItem value='I'>(I) Lotes não identificados</MenuItem>

                  <MenuItem value='End'>
                    (End) Endereços incompatíveis com o logradouro
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className='GerenciarAdversidades-form-group'>
              <FormControl
                sx={{ width: "100%", backgroundColor: "white" }}
                size='small'
              >
                <InputLabel>Setores</InputLabel>
                <Select
                  label='Tipo de Usuário'
                  value={selectedTipoUsuario}
                  onChange={(event) =>
                    setSelectedTipoUsuario(event.target.value)
                  }
                >
                  <MenuItem value=''>Todos os setores</MenuItem>
                  <MenuItem value='1'>Setor 1</MenuItem>
                  <MenuItem value='2'>Setor 2</MenuItem>
                  <MenuItem value='3'>Setor 3</MenuItem>
                  <MenuItem value='4'>Setor 4</MenuItem>
                  <MenuItem value='5'>Setor 5</MenuItem>
                  <MenuItem value='6'>Setor 6</MenuItem>
                  <MenuItem value='7'>Setor 7</MenuItem>
                  <MenuItem value='8'>Setor 8</MenuItem>
                  <MenuItem value='9'>Setor 9</MenuItem>
                </Select>
              </FormControl>
            </div> */}
          </div>

          <div style={{ width: "88%", height: "350px" }}>
            <TableContainer component={Paper} sx={{ height: "100%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Identificador
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Tipo de Adversidade
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(
                      currentPage * rowsPerPage,
                      currentPage * rowsPerPage + rowsPerPage
                    )
                    .map((adversidades, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: "11px", maxWidth: "180px" }}>
                          {adversidades.id}
                        </TableCell>
                        <TableCell sx={{ fontSize: "11px", maxWidth: "30px" }}>
                          {adversidades.type}
                        </TableCell>
                        <TableCell sx={{ fontSize: "11px", maxWidth: "30px" }}>
                          <Button
                            style={{ minWidth: "10px" }}
                            onClick={() => handleClickAdversidade(adversidades)}
                            variant='outlined'
                          >
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component='div'
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
              />
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default GerenciarAdversidades;
