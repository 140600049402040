import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';
import ModalAlterarLogradouro from "./ModalAlterarLogradouro/ModalAlterarLogradouro";
import { Api } from "../../services/Api.js";
import { faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import TextField from '@mui/material/TextField';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import { Map, View } from 'ol';
import proj4 from 'proj4';


import "./AlterarLogradouro.css";


const AlterarLogradouro = () => {
  const [initialData, setInitialData] = useState([]);
  const [informacoesLogradouro, setInformacoesLogradouro] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddLogradouro, setShowAddLogradouro] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searching, setSearching] = useState(false); //Adiciona o procurando durante a procura da API

  const [tamanhoContainerMapa, setTamanhoContainerMapa] = useState([]);
  const [tamanhoContainerLista, setTamanhoContainerLista] = useState([]);

  const mapRef = useRef(null);
  const [currentZoom, setCurrentZoom] = useState(5);


  //useState coordenadas
  const [coordenadas, setCoordenadas] = useState([-44.0524, -18.4833]);

  useEffect(() => {
    if (window.innerHeight < 750) {
      setTamanhoContainerMapa('560px')
      setTamanhoContainerLista('460px')
    }
    else {
      setRowsPerPage(8)
      setTamanhoContainerMapa('790px')
      setTamanhoContainerLista('690px')
    }
  }, [])

  useEffect(() => {
    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: [coordenadas[0], coordenadas[1]],
        zoom: currentZoom,
        projection: 'EPSG:4326',
      }),
    });


    return () => {
      newMap.dispose();
    };
  }, [coordenadas, currentZoom]);


  async function pesquisarLogradouro() {
    try {
      setSearching(true);
      let data = await Api.searchLogradouro(searchTerm);

      if (data.statusCode === undefined) {
        setFilteredData(data.data.logradouros);

        const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
        const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

        const utmCoordinates = data.data.pontoCentral.coordinates;
        const latLngCoordinates = proj4(utmProjection, latLngProjection, utmCoordinates);

        setCurrentZoom(18)
        setCoordenadas(latLngCoordinates)

      } else {
        // Adiciona um tratamento para logradouros não encontrados
        setFilteredData([]); // Limpa os dados filtrados
        alert("Logradouro não encontrado."); // Exibe um alerta
        setSearchTerm(''); // Limpa o termo de busca
      }

    } catch (error) {
      console.error("Erro ao pesquisar logradouro:", error); // Loga o erro no console
      setFilteredData([]); // Limpa os dados filtrados em caso de erro
      alert("Ocorreu um erro ao pesquisar. Tente novamente."); // Exibe um alerta de erro
    }
    setSearching(false);
  }

  useEffect(() => {
    if (searchTerm === '') {
      setCurrentZoom(5);
      const filteredEixos = initialData
      setCoordenadas([-44.0524, -18.4833]) //Reseta o Mapa
      setFilteredData(filteredEixos);
    }
  }, [initialData, searchTerm]);


  //Enter envia
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      pesquisarLogradouro();
    }
  };

  async function toggleAddLogradouro(informacoesLogradouro) {
    try {
      setSearching(true);
      const data = await Api.logradourosCadastrados(informacoesLogradouro);
      setInitialData([]);
      setShowAddLogradouro(!showAddLogradouro);
      setSearching(false);
      setInformacoesLogradouro(data);
    } catch (error) {
    }
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchLogradouro = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  function setValor(arg) {
    if ((arg.nome) !== null) {
      return arg.nome;
    } else {
      return "_";
    }
  }


  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ display: 'flex', flex: "1", flexDirection: "row", marginTop: '50px' }}>
        {/* MAPA */}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: "1", backgroundColor: "#AAD3DF" }}>
          <div ref={mapRef} style={{ width: '100%', height: tamanhoContainerMapa }} />
        </div>
        <div style={{ height: '100%', marginRight: '80px', marginLeft: '0', flex: "0.4" }}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <TextField
              style={{ flex: '1' }}
              size="small"
              label="Pesquisar..."
              value={searchTerm}
              onChange={handleSearchLogradouro}
              sx={{ backgroundColor: 'white' }}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div style={{ height: tamanhoContainerLista }} className="conteudo-lista-alterarLogradouro" >
            <TableContainer component={Paper} sx={{ height: '100%' }}>
              <Table>
                <TableHead >
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100%' }}>Logradouro</TableCell>
                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100%' }}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index} >
                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>
                          {setValor(item)}
                        </TableCell>
                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>
                          <ArrowCircleRightIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAddLogradouro(item.id)}
                            color="primary"
                            sx={{ fontSize: 40 }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} >

              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
              />
            </TableContainer>

          </div>
        </div>
      </div>

      <div className="barra-direita-alterarLogradouro">
        <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={pesquisarLogradouro}>
          {searching ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          )}
        </Fab>
      </div>

      {showAddLogradouro && (
        <section style={{ position: 'relative' }}>
          <ModalAlterarLogradouro onClose={() => setShowAddLogradouro(!showAddLogradouro)} informacoesLogradouro={informacoesLogradouro} />
        </section>
      )}
    </div>
  );
};

export default AlterarLogradouro;
