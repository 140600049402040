import React from "react";

import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { LoadContainer } from "./styles";

export type Props = {
  size?: FontAwesomeIconProps["size"];
  color?: string;
} & Omit<FontAwesomeIconProps, "icon" | "size" | "color">;

export function Loading({ size = "5x", color = "black", ...rest }: Props) {
  return (
    <LoadContainer>
      <FontAwesomeIcon
        size={size}
        color={color}
        icon={faSpinner}
        spin
        {...rest}
      />
    </LoadContainer>
  );
}
