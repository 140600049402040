import React from "react";

type Props = {
  indexColor: number;
  name: string;
};

export function TestadaLabel({ indexColor, name }: Props) {
  const colorsTestadas = [
    "rgb(23, 126, 137)",
    "rgb(219, 58, 52)",
    "rgb(255, 200, 87)",
    "rgb(8, 76, 97)",
    "rgb(84, 13, 110)",
    "rgb(238, 66, 102)",
    "rgb(14, 173, 105)",
    "rgb(228, 193, 249)",
    "rgb(152, 82, 119)",
    "rgb(0, 20, 39)",
  ];

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          background: colorsTestadas[indexColor],
          height: "20px",
          width: "20px",
          display: "inline-block",
          marginRight: "8px",
        }}
      />

      {name}
    </div>
  );
}
