import React, { useState, useEffect, useContext } from "react";
import { Text, Container, Content } from "./styles";
import { Loading } from "../../Components/common";

import { ColunaMapa } from "../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Components/ColunaMapa";
import { ColunaDados } from "../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Components/ColunaDados";

import { ContextAdv } from "../../Components/GerenciarAdversidades/ContextAdv";
import { ContextProviderInput } from "../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Context/ContextInputs";
import { MapProviderAdv } from "../../Components/GerenciarAdversidades/AdversidadeMesmoLote/Context/FuncoesMapa";

import {
  AdversidadesGlobalResponse,
  getAdversidadesGlobalService,
} from "../../services/getAdversidadesGlobalService";

import { InitialTable } from "./components/InitialTable";
import { TableRecadastramento } from "./components/TableRecadastramento";

enum VIEW_ADVERSIDADES {
  I = "I",
  C = "C",
  R = "R",
  End = "End",
  Erro = "Erro",
}

export function Adversidades() {
  const [erroText, setErroText] = useState<string>("");

  const [viewAdversidades, setViewAdversidades] =
    useState<VIEW_ADVERSIDADES | null>(null);

  const [adversidade, setAdversidade] = useState<
    AdversidadesGlobalResponse | undefined
  >(undefined);

  const { idAdv } = useContext(ContextAdv);

  function TypeAdversidadeMatch(
    apiResponse: AdversidadesGlobalResponse
  ): VIEW_ADVERSIDADES {
    const enumValue = Object.values(VIEW_ADVERSIDADES).find(function (value) {
      return value === apiResponse.adversidade.tipo;
    });
    if (!enumValue) {
      throw new Error("Tipo de adversidade desconhecido");
    }
    return enumValue;
  }

  async function fetchAdversidades() {
    try {
      const response = await getAdversidadesGlobalService(idAdv);
      setAdversidade(response);
      setViewAdversidades(TypeAdversidadeMatch(response));
    } catch (error: any) {
      setErroText(error.message || "Erro desconhecido");
      setViewAdversidades(VIEW_ADVERSIDADES.Erro);
    }
  }

  const [hasChoose, setHasChoose] = useState<{
    numReduzido: number | null;
    lotecod_novo?: number | null;
  } | null>(null);

  function handleProsseguirR(
    numReduzido: number | null,
    lotecod_novo: number | null
  ) {
    setHasChoose({
      numReduzido: numReduzido || null,
      lotecod_novo: lotecod_novo || null,
    });
  }

  useEffect(() => {
    fetchAdversidades();
  }, [idAdv]);

  return (
    <ContextProviderInput>
      <MapProviderAdv>
        <Container>
          <Content>
            {!viewAdversidades && <Loading />}

            {viewAdversidades === VIEW_ADVERSIDADES.Erro && (
              <Text>{erroText}</Text>
            )}

            {adversidade && viewAdversidades === VIEW_ADVERSIDADES.I && (
              <>
                <ColunaMapa responseAdversidade={adversidade} />
                <InitialTable
                  title='LOTE NÃO IDENTIFICADO'
                  adversidade={adversidade}
                />
              </>
            )}
            {adversidade && viewAdversidades === VIEW_ADVERSIDADES.C && (
              <>
                <ColunaMapa responseAdversidade={adversidade} />
                <InitialTable
                  title='LOTE COM MÚLTIPLAS UNIDADES E COBERTURAS'
                  adversidade={adversidade}
                  numReduzido={adversidade.lotes[0].reduzido}
                />
              </>
            )}
            {adversidade && viewAdversidades === VIEW_ADVERSIDADES.R && (
              <>
                <ColunaMapa responseAdversidade={adversidade} />
                <TableRecadastramento
                  handleProsseguirR={handleProsseguirR}
                  lotes={adversidade.lotes}
                  adversidade={adversidade}
                />
                {hasChoose?.numReduzido && (
                  <InitialTable
                    title='REMEMBRAMENTO'
                    adversidade={adversidade}
                    tipoAdversidade={VIEW_ADVERSIDADES.R}
                    numReduzido={hasChoose.numReduzido}
                    lotecod_novo={hasChoose.lotecod_novo}
                  />
                )}
              </>
            )}
            {adversidade && viewAdversidades === VIEW_ADVERSIDADES.End && (
              <>
                <ColunaMapa responseAdversidade={adversidade} />
                <InitialTable
                  title='ENDEREÇOS INCOMPATÍVEIS COM O LOGRADOURO'
                  adversidade={adversidade}
                  numReduzido={adversidade.lotes[0].reduzido}
                />
              </>
            )}
          </Content>
        </Container>
      </MapProviderAdv>
    </ContextProviderInput>
  );
}
