import React, { useState, useEffect } from "react";
import { Alert, Card, TableCell, TableRow, TextField, Grid, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { Api } from "../../services/Api.js";

import "./AlterarPerfil.css";

const AlterarPerfil = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [cpf, setCpf] = useState("");
  const [id, setId] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");

  const [informacoes, setInformacoes] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertSucesso, setShowAlertSucesso] = useState(false);
  const [showAlertFalhou, setShowAlertFalhou] = useState(false);

  //API
  useEffect(() => {
    const fetchData = async () => {
      const data = await Api.getAlterarPerfil();
      setInformacoes(data[0])

      setId(data[0].id)
      setNome(data[0].name)
      setEmail(data[0].email)
      setDataNascimento(data[0].dataNasc)
      setCpf(data[0].cpf)
      setTipoUsuario(data[0].role)
    }
    fetchData();
  }, [])


  const mudarPerfil = async () => {
    const ApiInfos = {
      "id": id,
      "name": nome,
      "email": email,
      "cpf": cpf,
      "dataNasc": dataNascimento,
      "role": tipoUsuario
    }

    const data = await Api.alterarPerfilRequest(ApiInfos);

    if ((informacoes.cpf === cpf) && (informacoes.email === email) && (informacoes.name === nome) && (informacoes.dataNasc === dataNascimento)) {
      setShowAlert(true);
      setShowAlertSucesso(false)
      setShowAlertFalhou(false)
      setTimeout(() => setShowAlert(false), 3000);
    } else {
      if (data.statusCode !== undefined) {
        setShowAlert(false)
        setShowAlertSucesso(false);
        setShowAlertFalhou(true);
        setTimeout(() => setShowAlertFalhou(false), 3000);
      } else {
        sessionStorage.setItem("infoUsuarioToken", data.data.token);
        setShowAlert(false)
        setShowAlertFalhou(false)
        setShowAlertSucesso(true);
        setTimeout(() => setShowAlertSucesso(false), 3000);
        setInformacoes(ApiInfos)
      }
    }
  }

  const handleNomeChange = (event) => {
    setNome(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleDataNascimentoChange = (event) => {
    setDataNascimento(event.target.value);
  };

  const handleCpfChange = (event) => {
    setCpf(event.target.value);
  };

  const handleTipoUsuarioChange = (event) => {
    setTipoUsuario(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="container-AlterarPerfil">
        <Card variant="outlined" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TableRow style={{ borderBottom: '2.5px solid #1976D2', width: '100%' }}>
            <TableCell style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h2>
                Alterar Perfil
              </h2>
            </TableCell>
          </TableRow>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 25 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    value={nome}
                    onChange={handleNomeChange}
                    sx={{ backgroundColor: "white", borderRadius: '59px' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Nascimento"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={dataNascimento}
                    onChange={handleDataNascimentoChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="CPF"
                    variant="outlined"
                    fullWidth
                    value={cpf}
                    onChange={handleCpfChange}
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tipo de Usuário"
                    variant="outlined"
                    fullWidth
                    value={tipoUsuario}
                    onChange={handleTipoUsuarioChange}
                    sx={{ background: '#f0f0f0' }}
                    readOnly
                    disabled
                  />
                </Grid>
              </Grid>
              <div className="button-container-AlterarPerfil">
                <Button onClick={mudarPerfil} variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </div>
      {showAlert && (<Alert sx={{ margin: 10 }} severity="warning">Altere as Informações</Alert>)}
      {showAlertSucesso && (<Alert sx={{ margin: 10 }} severity="success">Perfil Alterado</Alert>)}
      {showAlertFalhou && (<Alert sx={{ margin: 10 }} severity="error">Informações Incorretas</Alert>)}
    </div>
  );
};

export default AlterarPerfil;
