import React from "react";
import { Bounce, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export function ToastProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ToastContainer
        position='top-right' // Posição do toast
        autoClose={5000} // Tempo em milissegundos para fechar automaticamente
        hideProgressBar={false} // Esconder barra de progresso
        newestOnTop={false}
        closeOnClick // Fechar ao clicar
        rtl={false}
        pauseOnFocusLoss // Pausar ao perder foco
        draggable // Permitir arrastar
        pauseOnHover // Pausar ao passar o mouse
        theme='colored' // Tema do toast
        transition={Bounce}
      />
      {children}
    </>
  );
}
