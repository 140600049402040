import jsPDF from "jspdf";
import logo from "../assets/img/logo_ita.png";

export default class PdfInconsistenciaServices {
  constructor() {
    this.pdf = new jsPDF();
  }

  generatePdfDataList = (data) => {
    if (!data || data.length === 0) return;

    const date = new Date();
    const today = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      }/${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }/${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      }:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;

    this.pdf.line(10, 10, 200, 10);
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(8);
    this.pdf.text("PREFEITURA MUNICIPAL DE ITAJUBÁ", 12, 14);
    this.pdf.text("Emissão " + today, 198, 14, "right");
    this.pdf.line(10, 16, 200, 16);
    this.pdf.addImage(logo, "PNG", 12, 20, 14, 14);
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(12);
    this.pdf.text("PREFEITURA MUNICIPAL DE ITAJUBÁ", 30, 25);
    this.pdf.setFontSize(8);
    this.pdf.text("ESTADO DE MINAS GERAIS", 30, 30);
    this.pdf.setFontSize(8);
    this.pdf.text("RELATÓRIO DE INCONSISTÊNCIAS", 105, 45, "center");
    this.pdf.setFontSize(10);
    this.pdf.line(10, 47, 200, 47);

    let inicio = 55;
    let endPage = false;
    let altura = 280;

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      console.log(item);

      if (endPage) {
        this.pdf.addPage();

        this.pdf.line(10, 10, 200, 10);
        this.pdf.setTextColor(0, 0, 0);
        this.pdf.setFontSize(8);
        this.pdf.text("PREFEITURA MUNICIPAL DE ITAJUBÁ", 12, 14);
        this.pdf.text("Emissão " + today, 198, 14, "right");
        this.pdf.line(10, 16, 200, 16);
        this.pdf.addImage(logo, "PNG", 12, 20, 14, 14);
        this.pdf.setTextColor(0, 0, 0);
        this.pdf.setFontSize(12);
        this.pdf.text("PREFEITURA MUNICIPAL DE ITAJUBÁ", 30, 25);
        this.pdf.setFontSize(8);
        this.pdf.text("ESTADO DE MINAS GERAIS", 30, 30);
        this.pdf.setFontSize(8);
        this.pdf.text("RELATÓRIO DE INCONSISTÊNCIAS", 105, 45, "center");
        this.pdf.setFontSize(10);
        this.pdf.line(10, 47, 200, 47);

        inicio = 55;
      }

      if (i % 2 === 0) {
        this.pdf.setFillColor(221, 221, 221);
        this.pdf.rect(10, inicio - 5, 190, 35, "F");
      }

      // const inscricao = `01.${this.pad(item.loteNovo.setorCod, 2)}.${this.pad(
      //   item.loteNovo.quadraCod,
      //   3
      // )}.${this.pad(item.loteNovo.loteCod, 4)}${
      //   item.loteNovo.unidade ? "." + this.pad(item.loteNovo.unidade, 3) : ""
      // }`;

      this.pdf.setFontSize(8);
      this.pdf.text("LOTE", 12, inicio);
      this.pdf.text(item.loteId.toString(), 42, inicio);
      this.pdf.text("PROPRIETÁRIO", 85, inicio);
      this.pdf.text(item.proprietario ? (item.proprietario.nome ?? "Não informado") : "Não informado", 112, inicio);
      this.pdf.text("LOGRADOURO", 12, inicio + 5);
      this.pdf.text(
        item.endereco ? (item.endereco.logradouro ?? "Não informado") : "Não informado",
        42,
        inicio + 5
      );
      this.pdf.text("NÚMERO", 12, inicio + 10);
      this.pdf.text(
        item.endereco
          ? item.endereco.numero.toString()
          : "Não informado",
        42,
        inicio + 10
      );
      this.pdf.text("BAIRRO", 85, inicio + 10);
      this.pdf.text(item.endereco ? (item.endereco.bairro ?? "Não informado") : "Não informado", 112, inicio + 10);
      this.pdf.text("TIPOS", 12, inicio + 15);
      this.pdf.text(
        this.listInconsistencias(item),
        42,
        inicio + 15
      );
      this.pdf.text("DESCRIÇÃO", 12, inicio + 20);
      this.pdf.text(item.descricao, 42, inicio + 20);

      // if (item.isAreaTerreno || item.isAreaConstrucao) {
      //   this.pdf.text("ANTIGA ÁREA", 12, inicio + 25);
      //   this.pdf.setTextColor(19, 100, 181);
      //   this.pdf.text(item.lote.area_terreno, 42, inicio + 25);

      //   this.pdf.setTextColor(0, 0, 0);

      //   this.pdf.text("NOVA ÁREA", 85, inicio + 25);
      //   this.pdf.setTextColor(19, 100, 181);
      //   this.pdf.text(item.lote.nova_area_terreno, 112, inicio + 25);
      // }

      this.pdf.setTextColor(0, 0, 0);

      inicio += 40;
      if (inicio + 25 > altura - 10) endPage = true;
      else endPage = false;
    }

    this.pdf.save("relatorio_inconsistencias.pdf");
  };

  pad = (number, digits) => {
    if (number === null) return;

    if (typeof number === "number") number = number.toString();
    if (number.length >= digits) return number;

    const newNumber = "0".repeat(digits - number.length) + number;
    return newNumber;
  };

  listInconsistencias = (data) => {
    let list = [];

    if (data.isAreaTerreno) list.push("Área do Terreno");
    if (data.isAreaConstrucao) list.push("Área de Construção");
    if (data.isNovaUnidade) list.push("Nova Unidade");
    if (data.isDemolicao) list.push("Demolição");
    if (data.isNovaInscricao) list.push("Nova Inscrição");

    return list.join(" / ");
  };
}
