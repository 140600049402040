import { createContext, useState } from 'react';

const ContextAdv = createContext(); //cria o contexto

//crie o provedor do contexto
const ContextAdvProvider = ({ children }) => {
    const [idAdv, setIdAdv] = useState([]);
    const [estadoModal, setEstadoModal] = useState(false);
    const [message, setMessage] = useState([]); // Corrigido aqui!

    const handleSetEstadoModal = () => {
        setEstadoModal(!estadoModal);
    };

    return (
        <ContextAdv.Provider value={{ setMessage, message, setIdAdv, idAdv, handleSetEstadoModal, estadoModal }}>
            {children}
        </ContextAdv.Provider>
    );

};
export { ContextAdv, ContextAdvProvider };