import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { OSM } from "ol/source.js";
import { Tile as TileLayer } from "ol/layer.js";
import Compass from "ol-ext/control/Compass";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import { Style, Fill, Stroke, Text } from "ol/style";
import { Polygon } from "ol/geom";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { getLength as getLineLength } from "ol/sphere";
import MultiPolygon from "ol/geom/MultiPolygon";
import {ScaleLine, defaults as defaultControls} from 'ol/control.js';

import "./MapRender.css";

import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";


export default function MapRender({
  result,
  number,
  setValuesLat,
  setValuesLon,
}) {
  const mapRef = useRef();

  proj4.defs(
    "EPSG:31983",
    "+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  register(proj4);

  useEffect(() => {
    const ref = mapRef.current;
    const alreadyCreated = ref.querySelector(".ol-compass");

    if (alreadyCreated) return

    let center = [452673.985418907, 7520803.474062003];
    let rotation = 0;
    if (result.loteNovo) {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.loteNovo.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const extent = newSource.getExtent();

      const dx = extent[2] - extent[0];
      const dy = extent[3] - extent[1];

      rotation = Math.atan2(dy, dx);
      center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
    }


    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: center,
        zoom: 12,
        projection: "EPSG:31983",
        resolutions: resolutions,
        maxZoom: 20,
        minZoom: 0,
      }),
    });

    const compass = new Compass({
      className: "ol-compass",
      autoHide: false,
      src: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Compass-icon_bb_N.svg",
    });

    newMap.addControl(compass);

    const scaleLineControl = new ScaleLine({
      units: "metric",
      bar: true,
      steps: 4,
      text: true,
      minWidth: 140,
      maxWidth: 140,
      dpi: 96,
    });

    newMap.addControl(scaleLineControl);
    handleRenderLayers(newMap);

    return () => newMap.setTarget(null);
  }, [result]);

  const handleRenderLayers = (newMap) => {
    if (number === "1") handleFirstMap(newMap);
    if (number === "2") handleSecondMap(newMap);
    if (number === "3") handleThirdMap(newMap);

    if (result && result.logradouros) {
      result.logradouros.map((logradouro, index) => {
        const check = newMap.getLayers().getArray().find((layer) => {
          return layer.getProperties().name === "logradouro-" + index;
        });

        if (!check) {

          const newSource = new VectorSource({
            features: new GeoJSON().readFeatures(logradouro.geom, {
              dataProjection: "EPSG:31983",
              featureProjection: "EPSG:31983",
            }),
          });

          const vectorLayer = new VectorLayer({
            source: newSource,
            style: new Style({
              fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)",
              }),
              stroke: new Stroke({
                color: "#000000",
                width: 2,
              }),
              text: new Text({
                text: logradouro.nome || "",
                font: "12px Calibri,sans-serif",
                fill: new Fill({
                  color: "#000",
                }),
                stroke: new Stroke({
                  color: "#fff",
                  width: 3,
                }),
                placement: "line",
                maxAngle: 30,
              }),
            }),
          });

          vectorLayer.setProperties({
            name: "logradouro-" + index,
          });

          newMap.addLayer(vectorLayer);
        }
      });
    }

    newMap.render();
  };

  const handleFirstMap = (map) => {
    if (result && result.loteNovo) {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.loteNovo.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const vectorLayer = new VectorLayer({
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "#fafafa",
          }),
          stroke: new Stroke({
            color: "#0a0a0a",
            width: 1,
          }),
          zIndex: 1,
        }),
      });

      map.addLayer(vectorLayer);

      newSource.getFeatures().forEach((feature) => {
        const geometry = feature.getGeometry();

        if (geometry.getType() === "MultiPolygon") {
          const coordinates = geometry.getCoordinates()[0][0];

          for (let i = 0; i < coordinates.length - 1; i++) {
            const start = coordinates[i];
            const end = coordinates[i + 1];

            const distance = getLineLength(new LineString([start, end]), {
              projection: "EPSG:31983",
            });

            const midPoint = [
              (start[0] + end[0]) / 2,
              (start[1] + end[1]) / 2,
            ];

            midPoint[0] -= 1.2
            midPoint[1] -= 1.2

            const label = new VectorLayer({
              source: new VectorSource({
                features: [
                  new Feature({
                    geometry: new Point(midPoint),
                    name: `${distance.toFixed(2)} m`,
                  }),
                ],
              }),
              style: new Style({
                text: new Text({
                  text: `${distance.toFixed(2)} m`,
                  font: "12px Calibri,sans-serif",
                  fill: new Fill({
                    color: "#ff0000",
                  }),
                  stroke: new Stroke({
                    color: "#fff",
                    width: 3,
                  }),
                }),
              }),
            });

            map.addLayer(label);
          }
        }
      });

      const coordCenter = newSource.getExtent();
      const center = [(coordCenter[0] + coordCenter[2]) / 2, (coordCenter[1] + coordCenter[3]) / 2];

      const area = new VectorLayer({
        source: new VectorSource({
          features: [
            new Feature({
              geometry: new Point(center),
              name: `${getLineLength(new MultiPolygon(newSource.getFeatures()[0].getGeometry().getCoordinates()), {
                projection: "EPSG:31983",
              }).toFixed(2)} m²`,
            }),
          ],
        }),
        style: new Style({
          text: new Text({
            text: `${getLineLength(new MultiPolygon(newSource.getFeatures()[0].getGeometry().getCoordinates()), {
              projection: "EPSG:31983",
            }).toFixed(2)} m²`,
            font: "12px Calibri,sans-serif",
            fill: new Fill({
              color: "#0000ff",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
          }),
        }),
      });

      map.addLayer(area);
      map.getView().setZoom(12);
    }

    if (result && result.coberturas) {
      result.coberturas.map((cobertura) => {
        const newSource = new VectorSource({
          features: new GeoJSON().readFeatures(cobertura.geom, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
          }),
        });

        const vectorLayer = new VectorLayer({
          source: newSource,
          style: new Style({
            fill: new Fill({
              color: "rgba(128,128,128,0.3)",
            }),
            stroke: new Stroke({
              color: "#0a0a0a",
              width: 1,
            }),
            zIndex: 2,
          }),
        });

        map.addLayer(vectorLayer);
        map.getView().setZoom(15);

        newSource.getFeatures().forEach((feature) => {
          const geometry = feature.getGeometry();

          if (geometry.getType() === "Polygon") {
            const coordinates = geometry.getCoordinates()[0];

            for (let i = 0; i < coordinates.length - 1; i++) {
              const start = coordinates[i];
              const end = coordinates[i + 1];

              const distance = getLineLength(new LineString([start, end]), {
                projection: "EPSG:31983",
              });

              const midPoint = [
                (start[0] + end[0]) / 2,
                (start[1] + end[1]) / 2,
              ];

              const label = new VectorLayer({
                source: new VectorSource({
                  features: [
                    new Feature({
                      geometry: new Point(midPoint),
                      name: `${distance.toFixed(2)} m`,
                    }),
                  ],
                }),
                style: new Style({
                  text: new Text({
                    text: `${distance.toFixed(2)} m`,
                    font: "12px Calibri,sans-serif",
                    fill: new Fill({
                      color: "#000",
                    }),
                    stroke: new Stroke({
                      color: "#fff",
                      width: 3,
                    }),
                  }),
                }),
              });

              map.addLayer(label);
            }
          }
        });
      });
    }

    if (result && result.testadas) {
      result.testadas.map((testada) => {
        const newSource = new VectorSource({
          features: new GeoJSON().readFeatures(testada.geom, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
          }),
        });

        const vectorLayer = new VectorLayer({
          source: newSource,
          style: new Style({
            stroke: new Stroke({
              color: "#0000ff",
              width: 1,
            }),
            zIndex: 3,
          }),
        });

        map.addLayer(vectorLayer);

        const extensao = testada.extensao || 0;

        const coordinates = testada.geom.coordinates[0];
        const start = coordinates[0];
        const end = coordinates[1];
        const midPoint = [
          (start[0] + end[0]) / 2,
          (start[1] + end[1]) / 2,
        ];

        const label = new VectorLayer({
          source: new VectorSource({
            features: [
              new Feature({
                geometry: new Point(midPoint),
                name: `${extensao.toFixed(2)} m`,
              }),
            ],
          }),
          style: new Style({
            text: new Text({
              text: `${extensao.toFixed(2)} m`,
              font: "12px Calibri,sans-serif",
              fill: new Fill({
                color: "#0000ff",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 3,
              }),
            }),
          }),
        });

        map.addLayer(label);
      });
    }

    handleCalculateExtent(map);

    map.on("pointerdrag", (evt) => {
      handleCalculateExtent(map);
    });

    map.on("moveend", (evt) => {
      handleCalculateExtent(map);
    });
  };

  const handleSecondMap = (map) => {
    if (result && result.lotesDaQuadra) {
      result.lotesDaQuadra.map((lote) => {
        const newSource = new VectorSource({
          features: new GeoJSON().readFeatures(lote.geom, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
          }),
        });

        const vectorLayer = new VectorLayer({
          source: newSource,
          style: new Style({
            fill: new Fill({
              color: "rgba(233,102,172,0.2)",
            }),
            stroke: new Stroke({
              color: "#0a0a0a",
              width: 1,
            }),
            zIndex: 1,
          }),
        });

        map.addLayer(vectorLayer);
      });
    }

    if (result && result.quadra) {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.quadra.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const vectorLayer = new VectorLayer({
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          stroke: new Stroke({
            color: "#e966ac",
            width: 2,
          }),
          zIndex: 2,
        }),
      });

      map.addLayer(vectorLayer);
    }

    if (result && result.loteNovo) {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.loteNovo.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const vectorLayer = new VectorLayer({
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(254,234,55,0.5)",
          }),
          stroke: new Stroke({
            color: "#feea37",
            width: 2,
          }),
          zIndex: 3,
        }),
      });

      map.addLayer(vectorLayer);

      map.getView().setZoom(12);
    }
  };

  const handleThirdMap = (map) => {
    if (result && result.loteNovo && result.loteNovo.geom) {
      const loteLayer = new VectorLayer({
        source: new VectorSource({
          features: new GeoJSON().readFeatures(result.loteNovo.geom, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
          }),
        }),
        style: new Style({
          fill: new Fill({
            color: "rgba(217, 252, 255, 0)",
          }),
          stroke: new Stroke({
            color: "#0a0a0a",
            width: 1,
          }),
        }),
      });

      map.addLayer(loteLayer);

      const coordinates = result.loteNovo.geom.coordinates[0][0];
      const extent = map.getView().calculateExtent();
      const coordinatesExtent = [
        [extent[0], extent[1]],
        [extent[2], extent[1]],
        [extent[2], extent[3]],
        [extent[0], extent[3]],
        [extent[0], extent[1]],
      ];

      const temp = [
        coordinatesExtent[0],
        coordinatesExtent[1],
        coordinatesExtent[2],
        coordinatesExtent[3],
        coordinatesExtent[4],
        ...coordinates,
        coordinatesExtent[0],
      ];

      const polygon = new Polygon([temp]);

      const newSource = new VectorSource({
        features: [new Feature(polygon)],
      });

      const vectorLayer = new VectorLayer({
        properties: {
          name: "loteNovo",
        },
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 1)",
          }),
        }),
      });

      map.addLayer(vectorLayer);
      map.getView().setMinZoom(13);
      map.getView().setZoom(14);
    }
  };

  const handleCalculateExtent = (map) => {
    const extend = map.getView().calculateExtent();

    const bottomLine = new LineString([
      [extend[0], extend[1]],
      [extend[2], extend[1]],
    ]);

    const leftLine = new LineString([
      [extend[0], extend[1]],
      [extend[0], extend[3]],
    ]);

    const leftLinePoints = [
      leftLine.getCoordinateAt(0)[1].toFixed(2),
      leftLine.getCoordinateAt(0.3333333333333333)[1].toFixed(2),
      leftLine.getCoordinateAt(0.6666666666666666)[1].toFixed(2),
      leftLine.getCoordinateAt(1)[1].toFixed(2),
    ];

    const bottomLinePoints = [
      bottomLine.getCoordinateAt(0)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.2)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.4)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.6)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.8)[0].toFixed(2),
      bottomLine.getCoordinateAt(1)[0].toFixed(2),
    ];

    setValuesLat(leftLinePoints);
    setValuesLon(bottomLinePoints);
  }

  return (
    <div className="map" ref={mapRef}>
    </div>
  );
}

const resolutions = [
  2785.522278112486, 1392.761139056243, 696.3805695281216, 348.1902847640608,
  174.0951423820304, 87.0475711910152, 43.5237855955076, 21.7618927977538,
  10.8809463988769, 5.44047319943845, 2.720236599719225, 1.3601182998596124,
  0.6800591499298062, 0.3400295749649031, 0.1700147874824516,
  0.0850073937412258, 0.0425036968706129, 0.0212518484353064,
  0.0106259242176532, 0.0053129621088266, 0.0026564810544133,
];
