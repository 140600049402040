import React, { useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ManegerOpenStreet } from "../../../Map/OpenStreetMap/ManegerOpenStreet";
import { Api } from "../../../services/Api";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DoneIcon from '@mui/icons-material/Done';
import { CardHeader, CardMedia } from "@mui/material";

function RecipeReviewCard({ inviteStreetView, imagem, onDelete, onAdd, config }) {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const isImageInConfig = config.some(img => img.nome_imagem === imagem.nome_imagem); // Verifica se a imagem está em config

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '4px', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
                sx={{
                    width: '100%',
                    padding: '2px', // Reduz o padding do CardHeader
                    borderBottom: '1px solid #ccc', // Adiciona uma borda
                    '& .MuiCardHeader-action': {
                        width: '100%',
                        margin: 0, // Remove a margem padrão da ação
                    },
                }}
                action={
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <div style={{
                            width: 30, height: 30, backgroundColor: isImageInConfig ? '#A1E8A1' : undefined,
                            borderRadius: '100%', padding: 2, cursor: 'pointer', color: 'gray',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }} onClick={onAdd}>
                            <DoneIcon fontSize="inherit" />
                        </div>
                        <div style={{
                            width: 30, height: 30,
                            borderRadius: '100%', padding: 2, cursor: 'pointer', color: 'gray',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }} onClick={onDelete}>
                            <DoNotDisturbOnIcon fontSize="inherit" />
                        </div>
                    </div>
                }
            />
            <div style={{ flexGrow: 1, position: 'relative', overflow: 'hidden', cursor: 'pointer' }} onClick={() => {
                inviteStreetView(imagem)
            }}>
                <CardMedia
                    component="img"
                    alt="Imagem do AWS"
                    height="100"
                    image={imagem.url} // Use a URL da imagem armazenada no state
                />
            </div>
        </div>
    );
}

export const PanoramaImoveis = (props) => {
    const [imagemTemporaria, setImagemTemporaria] = useState([]); // Mantém o estado das imagens
    const [imagensSelecionadas, setImagensSelecionadas] = useState([]); // Novo estado para imagens selecionadas
    const [urlManegerOpenStreet, setUrlManegerOpenStreet] = useState(''); // Novo estado para a URL

    const fetch = async () => {
        try {
            const imagemAWS = await Api.getImagensStreetView(props.id);
            const imagens = imagemAWS[0].images.map(item => item);
            setImagemTemporaria(imagens); // Atualiza o estado com a imagem temporária
        } catch (error) {
            console.error('Erro ao buscar a imagem do AWS:', error);
        }
    }

    useEffect(() => {
        fetch();
    }, [props.id]);

    const handleAddImage = (imagem) => {
        setImagensSelecionadas(prev => {
            const isSelected = prev.some(img => img.nome_imagem === imagem.nome_imagem);
            if (!isSelected) {
                return [...prev, { ...imagem, selected: true }]; // Adiciona a imagem se não estiver selecionada
            }
            return prev; // Retorna o estado anterior se já estiver selecionada
        });
    };

    const handleDeleteImagem = (imagemDeletada) => {
        setImagensSelecionadas(prev => prev.filter(img => img.nome_imagem !== imagemDeletada.nome_imagem)); // Remove a imagem
        setImagemTemporaria(prev => prev.map(imagem => imagem.nome_imagem === imagemDeletada.nome_imagem ? { ...imagem, selected: false } : imagem)); // Atualiza o estado das imagens temporárias
    };

    const handleInviteStreetView = (imagem) => {
        setUrlManegerOpenStreet(imagem);
    };

    return (
        <div style={{ width: '100%', display: 'flex', gap: 10, justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
                <div style={{ minWidth: '100%', height: '80vh' }}>
                    <ManegerOpenStreet url={urlManegerOpenStreet} />
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <Grid container spacing={2} sx={{ paddingLeft: 0 }}>
                    {imagemTemporaria.map((imagem) => (
                        <Grid item xs={12} sm={4} md={4} key={imagem.nome_imagem} sx={{ height: 150 }}>
                            <RecipeReviewCard
                                inviteStreetView={handleInviteStreetView} // Passa a nova função
                                imagem={imagem}
                                onDelete={() => handleDeleteImagem(imagem)}
                                onAdd={() => handleAddImage(imagem)}
                                config={imagensSelecionadas}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
}
