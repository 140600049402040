import styled from "styled-components";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  Button as MuiButton,
  Box as MuiBox,
} from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const Card = styled(MuiCard).attrs({
  variant: "outlined",
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  padding: 5px;
`;

export const Title = styled.h5`
  margin-top: 5px;
  margin-left: 10px;
  width: 100%;
`;

export const Divider = styled(MuiDivider)`
  margin: 5px;
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
`;

export const Button = styled(MuiButton).attrs({
  variant: "contained",
})`
  width: 200px;
  display: flex;
  gap: 5px;
`;

export const Box = styled(MuiBox)`
  border: 1px solid black;
  padding: 10px;
  flex: 1;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 10px;
`;
