import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";

import { faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Results.css";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const headCells = [
  { id: "id", numeric: true, label: "ID", visible: false },
  { id: "inscricao", numeric: false, label: "Lote ID", visible: true },
  { id: "regra", numeric: false, label: "Regra", visible: true },
  { id: "proprietario", numeric: false, label: "Proprietário", visible: true },
  { id: "logradouro", numeric: false, label: "Logradouro", visible: true },
  { id: "bairro", numeric: false, label: "Bairro", visible: true },
];

const pad = (number, digits) => {
  if (number === null) return;

  if (typeof number === "number") number = number.toString();
  if (number.length >= digits) return number;

  const newNumber = "0".repeat(digits - number.length) + number;
  return newNumber;
};

function EnhancedTableHead({
  numSelected,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all rows" }}
          />
        </TableCell>
        {headCells
          .filter((cell) => cell.visible)
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        <TableCell align="center">Ações</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

function Results({
  results,
  selectedResults,
  setSelectedResults,
  setResult,
  isSearch,
  loading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsLength, setRowsLength] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const rows = results.map((row) => ({
      ...row,
      id: row.id,
      regra: handleListInconsistencias(row),
      inscricao: row.loteId,
      // `01.${pad(row.loteNovo.setorCod, 2)}.${pad(
      //   row.loteNovo.quadraCod,
      //   3
      // )}.${pad(row.loteNovo.loteCod, 4)}${
      //   row.loteNovo.unidade ? "." + pad(row.loteNovo.unidade, 3) : ""
      // }`,
      proprietario: row.proprietario
        ? row.proprietario.nome || "Não informado"
        : "Não informado",
      logradouro: row.endereco
        ? row.endereco.logradouro || "Não informado"
        : "Não informado",
      bairro: row.proprietario ? row.endereco.bairro || "Não informado" : "Não informado",
    }));

    setRows(rows);
    setRowsLength(rows.length);

    if (rows.length === 0) {
      setSelectedResults([]);
    }
  }, [results]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = results.map((n) => n.id);
      setSelectedResults(newSelected);
      return;
    }
    setSelectedResults([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedResults.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedResults, id];
    } else {
      newSelected = selectedResults.filter((item) => item !== id);
    }
    setSelectedResults(newSelected);
  };

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      [...rows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleListInconsistencias = (data) => {
    let list = [];

    if (data.isAreaTerreno) list.push("Área do Terreno");
    if (data.isAreaConstrucao) list.push("Área de Construção");
    if (data.isNovaUnidade) list.push("Nova Unidade");
    if (data.isDemolicao) list.push("Demolição");
    if (data.isNovaInscricao) list.push("Nova Inscrição");

    return list.join(" / ");
  };

  return (

    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} stickyHeader>
            <EnhancedTableHead
              numSelected={selectedResults.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsLength}
            />
            <TableBody>
              {!loading &&
                visibleRows.map((row, index) => {
                  const isItemSelected = selectedResults.includes(row.id);
                  const labelId = `table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      {headCells
                        .filter((cell) => cell.visible)
                        .map((cell) => (
                          <TableCell
                            key={cell.id}
                            align={cell.numeric ? "right" : "left"}
                          >
                            {row[cell.id]}
                          </TableCell>
                        ))}
                      <TableCell align="center">
                        <Tooltip title="ver detalhes">
                          <IconButton onClick={() => setResult(row)}>
                            <FontAwesomeIcon icon={faEye} size="xs" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {isSearch && !loading && rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Não há resultados para serem exibidos.
                  </TableCell>
                </TableRow>
              )}
              {!isSearch && !loading && rows.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                    style={{ padding: 100 }}
                  >
                    Realize uma busca para exibir os resultados.
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "60px 0px",
                      }}
                    >
                      <FontAwesomeIcon
                        size="3x"
                        icon={faSpinner}
                        color="#1976d2"
                        spin
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (
          <div className="pagination">
            <p>
              {selectedResults.length} de {rows.length} registros
              selecionados
            </p>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowsLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Registros por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
            />
          </div>
        )}
      </Paper>
    </Box>

  );
}

Results.propTypes = {
  results: PropTypes.array.isRequired,
  setResult: PropTypes.func.isRequired,
};

export default Results;
