const url = process.env.REACT_APP_API;

type Props = {
  lote_id_to_review: number;
  nome_lote: string;
  flag: number;
  tipo: string;
  observacoes: string;
};

export async function postReportAdversidadesService({
  lote_id_to_review,
  nome_lote,
  flag,
  tipo,
  observacoes,
}: Props) {
  try {
    const response = await fetch(url + "/adversidades/report", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify({
        lote_id_to_review,
        nome_lote,
        flag,
        tipo,
        observacoes,
      }),
    });
    const resp = await response.json();

    if (resp.status !== 201) {
      throw new Error();
    }
    return resp;
  } catch (error) {
    throw new Error(
      "Ocorreu um erro ao reportar.\n Tente novamente mais tarde"
    );
  }
}
