const url = process.env.REACT_APP_API;

type Props = {
  cod: number;
};

export async function getBicByCod({ cod }: Props) {
  try {
    const response = await fetch(url + "/bic/info/" + cod, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "ngrok-skip-browser-warning": "any",
      },
    });
    const resp = await response.json();
    if (resp.status !== 200) {
      throw new Error();
    }
    return resp;
  } catch (error) {
    throw new Error(
      "Ocorreu um erro ao buscar BIC.\nTente novamente mais tarde"
    );
  }
}
