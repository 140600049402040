import { CardContent, Grid, Typography, CardMedia, Card, IconButton, CardHeader, Button } from "@mui/material";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { useRef, useState, useEffect } from "react";
import { Api } from "../../../../../../services/Api";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';

const UploadComponent = ({ loteId }) => {
    const fileInputRef = useRef(null);
    const [images, setImages] = useState([]);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            addImage(URL.createObjectURL(file));
        }
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.startsWith("image/")) {
                const file = items[i].getAsFile();
                addImage(URL.createObjectURL(file));
            }
        }
    };

    const addImage = (imageUrl) => {
        setImages((prevImages) => [...prevImages, imageUrl]);
    };

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const saveUploads = async () => {
        const aux = await Api.postTestUpload(images, loteId, 10);
        if (aux?.arquivos?.arquivos.length > 0)
            alert('Uploads enviados com sucesso')
        else {
            alert('falha ao enviar uploads')
        }
    };

    useEffect(() => {
        document.addEventListener("paste", handlePaste);
        return () => document.removeEventListener("paste", handlePaste);
    }, []);

    return (
        <>
            <Grid item xs={12} sm={6} md={4} >
                <Button disabled={images.length === 0} onClick={saveUploads}>Enviar Uploads</Button>
                <CardContent onClick={handleClick} sx={{
                    height: 135,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    '&:last-child': { paddingBottom: '16px' },
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#f5f5f5',
                    },
                }}>
                    <ImageSearchIcon sx={{ fontSize: 60, color: '#999', mb: 2 }} />
                    <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                        Adicione uma imagem!
                    </Typography>
                </CardContent>
                {/* Input de arquivo oculto */}
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />

            </Grid>

            {images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <div style={{ border: '1px solid #ccc', borderRadius: '4px', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader
                            sx={{
                                width: '100%',
                                padding: '2px',
                                borderBottom: '1px solid #ccc',
                                '& .MuiCardHeader-action': {
                                    width: '100%',
                                    margin: 0,
                                },
                            }}
                            action={
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <IconButton aria-label="delete" size="small" onClick={() => removeImage(index)}>
                                        <DoNotDisturbOnIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            }
                        />
                        <div style={{ flexGrow: 1, position: 'relative', overflow: 'hidden', cursor: 'pointer', objectFit: 'contain' }}>
                            <CardMedia
                                component="img"
                                height="95"
                                image={imageUrl}
                                alt={`Imagem ${index + 1}`}
                                sx={{
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            ))}
        </>
    );
};

export default UploadComponent;
