import React from "react";
import { Container, Image, Card } from "./styles";
import logo_itajuba from "../../../../assets/img/logo_itajuba.png";
import { jwtDecode } from "jwt-decode";

export function Home() {
  const [user, setUser] = React.useState<{
    name: string;
    role: string;
  } | null>(null);

  const token = sessionStorage.getItem("infoUsuarioToken");

  React.useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const usuarioInfo = JSON.parse(decodedToken.sub || "");
      setUser({
        role: usuarioInfo.role,
        name: usuarioInfo.name ? usuarioInfo.name : "Nome não cadastrado",
      });
    }
  }, [token]);

  return (
    <Container>
      <Image src={logo_itajuba} alt='logo_itajuba' />
      <Card>
        <h1>Bem-vindo ao Recadastramento de Itajubá!</h1>
        {user && (
          <>
            <h2>
              {user.name}
              <strong>, cargo: </strong>
              {user.role}
            </h2>
          </>
        )}

        <p>
          Através deste recadastramento, você será capaz de realizar a
          identificação, registro e atualização dos imóveis da cidade de
          Itajubá.
        </p>
      </Card>
    </Container>
  );
}
