import proj4 from "proj4";
const utmProjection = "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

const configurarCoordenada = (coordinetesFlat) => {
    return coordinetesFlat.map((item) => proj4(utmProjection, latLngProjection, item))
};

export const configurarCoordenadaCentral = (coordinetesFlat) => {
    return coordinetesFlat = proj4(utmProjection, latLngProjection, coordinetesFlat)
};

export const formaterCoordinatesPolygon = (dados) => {

    try {
        let dadosPadronizados;
        if (dados.coordenadas) {
            dadosPadronizados = [dados];
        } else {
            dadosPadronizados = dados;
        }

        const arraysLayers = dadosPadronizados.map((arrayLayer) => {
            // return { coordenadas: arrayLayer.coordenadas, id: arrayLayer.id }

            return { coordenadas: arrayLayer.coordenadas.map((item) => item.map((x) => configurarCoordenada(x))), id: arrayLayer.id }
        })

        return arraysLayers
    }
    catch (error) {
        console.log("Erro ao Formatar", error);
    }
}

export const calcularCentroide = (coordenadas) => {
    let somaX = 0;
    let somaY = 0;
    let totalPontos = coordenadas.length;

    coordenadas.forEach(coordenada => {
        somaX += coordenada[0];
        somaY += coordenada[1];
    });

    let centroideX = somaX / totalPontos;
    let centroideY = somaY / totalPontos;

    return [centroideX, centroideY];
};