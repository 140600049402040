import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { Tab, Tabs } from "@mui/material"; // Adicione esta importação

import {
  calcularCentroide,
  configurarCoordenadaCentral,
} from "../../../../../Map/Hooks/FormaterCoordinates";
import ManegerVectorLayer from "../../../../../Map/Component/VectorManager";
import { Api } from "../../../../../services/Api";
import { ManegerOpenStreet } from "../../../../../Map/OpenStreetMap/ManegerOpenStreet";
const beiralOptions = ["Atípico", "Cobertura", "Inexistente", "Padrão"];

const TabMapa = ({
  geometriaLoteColeta,
  responseLoteForId,
  getCoberturas,
  url,
}) => {
  const mapManger = useRef(null);
  const vectorManeger = useRef();
  const editColor = (item) => {
    if (item.flag === 1) {
      return "rgb(205,235,176, 0.8)";
    }
    if (item.flag === 2) {
      return "rgba(114,178,137, 0.8)";
    } else {
      return "rgb(229, 115, 115, 0.8)";
    }
  };

  const [value, setValue] = useState(0); // Adicione este estado

  const handleChange = (event, newValue) => {
    // Adicione esta função
    setValue(newValue);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await Api.getMarchaUrbana();
        const resultResponse =
          response.data.manchaUrbana.ponto_central.coordinates;
        const coordendaCentral = configurarCoordenadaCentral(resultResponse);
        if (!vectorManeger.current) {
          vectorManeger.current = new ManegerVectorLayer(coordendaCentral);
          vectorManeger.current.setMapElement(mapManger.current);

                    const arrayIterarUnidades = Array.from({ length: responseLoteForId.unidades.length }, (_, index) => index);

                    const coberturas = arrayIterarUnidades.map((_, index) => {
                        if (responseLoteForId.unidades[index].areaCoberta.length > 0) {
                            const unidade = responseLoteForId.unidades[index].areaCoberta.map((item) => {
                                return { coordenadas: [item.geom.coordinates], id: { id: item.id, cod: item.loteCod } }
                            });
                            return unidade
                        } return []
                    })
                    const coberturasUnidas = coberturas.flat().filter((v, i, a) => a.findIndex(t => (t.id.id === v.id.id)) === i);
                    let testadas = []

                    if (responseLoteForId.testada.length > 0) {
                        testadas = responseLoteForId.testada.map((item) => {
                            return { coordenadas: [item.geom.coordinates], id: item.id }
                        });
                    }
                    const testadasUnidas = testadas.flat();

          vectorManeger.current.setCenterElement(
            configurarCoordenadaCentral(
              calcularCentroide(geometriaLoteColeta[0].coordenadas[0][0])
            )
          );
          vectorManeger.current.setZoomElement(17);
          vectorManeger.current.iniciarVectorLayer(
            "Lote",
            geometriaLoteColeta,
            null,
            () => "rgba(160,157,164, 0.8)"
          );
          vectorManeger.current.iniciarVectorLayer(
            "Testada",
            testadasUnidas,
            null,
            () => "black"
          );
          vectorManeger.current.iniciarVectorLayer(
            "Cobertura",
            coberturasUnidas,
            null
          );
          const dadosCobertura = vectorManeger.current
            .getLayersMap("Cobertura")
            .map((item) => item.DADOS);
          getCoberturas(dadosCobertura);
        }
      } catch (error) {
        console.log("Erro ao encontrar informações do lote");
      }
    };
    fetch();
  }, [responseLoteForId, geometriaLoteColeta]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Tabs
        style={{ display: "flex", width: "100%" }}
        value={value}
        onChange={handleChange}
      >
        <Tab label='Mapa' />
        <Tab label='VISÃO 360' />
      </Tabs>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: value === 0 ? "block" : "none",
        }}
        ref={mapManger}
      />{" "}
      {/* Modifique esta linha */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: value === 1 ? "block" : "none",
        }}
      >
        {" "}
        <ManegerOpenStreet url={url} />
      </div>
    </div>
  );
};

export default TabMapa;
