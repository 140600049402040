import { React, useEffect, useState } from "react";
import "./Inconsistencias.css";
import ResultPage from "./components/ResultPage/ResultPage";
import SearchPage from "./components/SearchPage/SearchPage";

const Inconsistencias = () => {
  const [allResults, setAllResults] = useState([]);
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(true);

  return (
    <div className="container">
      {result ? (
        <ResultPage
          selectedResult={result}
          setSelectedResult={setResult}
          allResults={allResults}
          setAllResults={setAllResults}
        />
      ) : (
        <SearchPage
          results={allResults}
          setResults={setAllResults}
          setResult={setResult}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </div>
  );
};

export default Inconsistencias;
