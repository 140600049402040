import React from 'react';
import { Box, TextField, MenuItem } from '@mui/material';

class TabLote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loteData: this.inicializarLoteData(props.inputsInit)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inputsInit !== this.props.inputsInit && this.props.inputsInit) {
            this.setState({
                loteData: this.inicializarLoteData(this.props.inputsInit)
            });
        }

        if (prevProps.save !== this.props.save && this.props.save) {
            console.log('A prop "save" foi ativada!: ');
            this.props.onUpdate(this.state.loteData)
        }
    }

    inicializarLoteData(inputsInit) {
        return {
            ocupacao: inputsInit?.ocupacao || '',
            muro: inputsInit?.muro || '',
            passeio: inputsInit?.passeio || '',
            nivel: inputsInit?.nivel || '',
            situacaoLote: inputsInit?.situacaoLote || '',
            muroPasseio: inputsInit?.muroPasseio || '',
            utilizacao: inputsInit?.utilizacao || ''
        };
    }

    handleChange = (field, value) => {
        this.setState(prevState => ({
            loteData: {
                ...prevState.loteData,
                [field]: value
            }
        }));
    }

    render() {
        const { loteData } = this.state;
        const optionsLote = {
            nivel: [{ label: "Ao Nível", value: "Ao Nível" }, { label: "Abaixo", value: "Abaixo" }, { label: "Acima", value: "Acima" }, { label: "Irregular", value: "Irregular" }],
            ocupacao: [
                { label: "Edificado", value: "Edificado" },
                { label: "Vago", value: "Vago" },
                { label: "Construção em Andamento", value: "Construção em Andamento" },
                { label: "Construção Paralisada", value: "Construção Paralisada" },
                { label: "Ruínas", value: "Ruínas" },
                { label: "Em Reforma", value: "Em Reforma" },
                { label: "Em Demolição", value: "Em Demolição" },
                { label: "Praça", value: "Praça" }
            ],
            situacaoLote: [
                { label: "Esquina/+ de 1 frente", value: "Esquina/+ de 1 frente" },
                { label: "Meio de Quadra", value: "Meio de Quadra" },
                { label: "Encravado", value: "Encravado" },
                { label: "Gleba", value: "Gleba" },
                { label: "Vila/Condomínio", value: "Vila/Condomínio" },
                { label: "Sítio Rec.", value: "Sítio Rec." },
            ],
            muro: [
                { label: "Sim", value: "Sim" },
                { label: "Não", value: "Não" },
            ],
            passeio: [
                { label: "Sim", value: "Sim" },
                { label: "Não", value: "Não" },
            ],
        };

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                    select
                    label="Ocupação"
                    fullWidth
                    value={loteData.ocupacao}
                    onChange={(e) => this.handleChange('ocupacao', e.target.value)}
                >
                    {optionsLote.ocupacao.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Nível"
                    fullWidth
                    value={loteData.nivel}
                    onChange={(e) => this.handleChange('nivel', e.target.value)}
                >
                    {optionsLote.nivel.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Situação do Lote"
                    fullWidth
                    value={loteData.situacaoLote}
                    onChange={(e) => this.handleChange('situacaoLote', e.target.value)}
                >
                    {optionsLote.situacaoLote.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Muro"
                    fullWidth
                    value={loteData.muro}
                    onChange={(e) => this.handleChange('muro', e.target.value)}
                >
                    {optionsLote.muro.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Passeio"
                    fullWidth
                    value={loteData.passeio}
                    onChange={(e) => this.handleChange('passeio', e.target.value)}
                >
                    {optionsLote.passeio.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        );
    }
}

export default TabLote;
