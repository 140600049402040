import * as yup from "yup";

export const validationSchema = {
  saveAdversidades: yup.object().shape({
    numCodigo: yup.string().required("Campo obrigatório"),
    valueRadioGroupTestada: yup.string().required("Selecione uma testada"),
    areaEspecial: yup.string().required("Campo obrigatório"),
    unidades: yup.array().of(
      yup.object().shape({
        enderecologradouro_novo: yup.string().required("Campo obrigatório"),
        enderecocomplemento: yup.string().required("Campo obrigatório"),
        enderecobairro: yup.string().required("Campo obrigatório"),

        endereconumero: yup.string().required("Campo obrigatório"),
      })
    ),
    endereco: yup.object().shape({
      logradouro_novo: yup.string().when("$showEnderecoLote", {
        is: true,
        then: (schema) => schema.required("Campo obrigatório"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  }),
};
