import { React, useState } from "react";
import Form from "./Form/Form";
import Results from "./Results/Results";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal, Box, Typography } from "@mui/material";
import InconsistenciaServices from "../../../../services/inconsistenciaServices";
import PdfInconsistenciaServices from "../../../../services/pdfInconsistenciaServices";

import "./SearchPage.css";

function SearchPage({ results, setResults, setResult }) {
  const [loading, setLoading] = useState(false);
  const [selectedResults, setSelectedResults] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const inconsistenciaServices = new InconsistenciaServices();
  const pdfServices = new PdfInconsistenciaServices();

  const handlePrint = () => {
    const data = results.filter((result) =>
      selectedResults.includes(result.id)
    );

    pdfServices.generatePdfDataList(data);
  };

  const handleCheck = () => {
    const ids = selectedResults;

    inconsistenciaServices.markAsPrinted(ids).then((response) => {
      console.log("Marked as printed:", ids);
    });
  };

  return (
    <div className="search">
      <Form
        results={results}
        setResults={setResults}
        loading={loading}
        setLoading={setLoading}
        isSearch={isSearch}
        setIsSearch={setIsSearch}
        showPrint={selectedResults.length > 0}
        showSend={selectedResults.length > 0}
        showCheck={selectedResults.length > 0}
        onHelp={() => setShowHelp(true)}
        onPrint={handlePrint}
        onCheck={handleCheck}
      />
      <Results
        results={results}
        selectedResults={selectedResults}
        setSelectedResults={setSelectedResults}
        setResult={setResult}
        isSearch={isSearch}
        loading={loading}
      />
      <HelpModal visible={showHelp} setVisible={setShowHelp} />
    </div>
  );
}

function HelpModal({ visible, setVisible }) {
  return (
    <Modal
      className="modal-background"
      open={visible}
      onClose={() => setVisible(false)}
    >
      <Box className="modal">
        <Typography className="modal-header">
          <span className="icon">
            <FontAwesomeIcon icon={faQuestion} size="2x" color="#3b3b3b" />
          </span>
          <span className="title"> Perguntas e respostas</span>
          <button className="close" onClick={() => setVisible(false)}>
            <FontAwesomeIcon icon={faXmark} size="1x" color="#FD5261" />
          </button>
        </Typography>
        <Typography className="modal-body">
          <span>Titulo</span>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
            laudantium necessitatibus, natus aliquid, error accusamus odio,
            quasi similique molestiae tempora mollitia. Ad esse unde earum?Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Enim vitae
            veritatis, accusantium a sint tenetur hic laudantium quas
            consequuntur, ut similique deserunt modi expedita, cupiditate
            officia aut nesciunt facere. Hic obcaecati consequatur possimus
            dolorem nisi.
          </span>
        </Typography>
      </Box>
    </Modal>
  );
}

export default SearchPage;
