import React from "react";
import { TableCell } from "@mui/material";
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import "./ExportarDados.css";
import { Card, Button, ContainerOpcoes } from "./styles";

import { getExportDadosService } from "../../services/getExportDadosService";

interface IOpcoes {
  nome: string;
  rota: string;
  filename: string;
}

const ExportarDados = () => {
  const opcoes: IOpcoes[] = [
    {
      nome: "Proprietários",
      rota: "proprietario",
      filename: "relatorio_proprietarios.csv",
    },
    {
      nome: "Logradouros",
      rota: "logradouros",
      filename: "relatorio_logradouros.csv",
    },
    { nome: "Eixos", rota: "Eixos", filename: "relatorio_eixos.csv" },
    {
      nome: "Endereços",
      rota: "enderecos",
      filename: "relatorio_enderecos.csv",
    },
    { nome: "Lotes", rota: "lotes", filename: "relatorio_lotes.csv" },
    {
      nome: "Edificações",
      rota: "edificacoes",
      filename: "relatorio_edificacoes.csv",
    },
    {
      nome: "Pontos de Interesse",
      rota: "pontointeresse",
      filename: "relatorio_pontos_interesse.csv",
    },
  ];

  async function handleDownload(rota: string, filename: string) {
    try {
      await getExportDadosService({ rota: rota, filename: filename });
    } catch (error: any) {
      window.alert(error.message);
    }
  }

  return (
    <div className='container-ExportarDados'>
      <Card>
        <TableContainer component={Paper} sx={{ height: "100%" }}>
          <Table>
            <TableRow
              style={{ borderBottom: "2.5px solid #1976D2", width: "100%" }}
            >
              <TableCell
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2>Exportar Dados</h2>
              </TableCell>
            </TableRow>

            <TableContainer component={Paper} sx={{ height: "100%" }}>
              <Table sx={{ minWidth: "100%" }} aria-label='caption table'>
                <TableBody>
                  {opcoes.map((opcao, index) => (
                    <TableRow key={index}>
                      <ContainerOpcoes>{opcao.nome}</ContainerOpcoes>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleDownload(opcao.rota, opcao.filename)
                          }
                        >
                          CSV
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};

export default ExportarDados;
