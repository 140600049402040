import { downloadFile } from "../hooks/downloadFile";
const url = process.env.REACT_APP_API;

interface IProps {
  rota: string;
  filename: string;
}

export async function getExportDadosService({ rota, filename }: IProps) {
  try {
    const response = await fetch(url + `/relatorios/${rota}`, {
      method: "GET",
      headers: {
        Accept: "text/csv",
        Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "ngrok-skip-browser-warning": "any",
      },
    });
    console.log(response);
    if (!response.ok) {
      throw new Error("Erro ao baixar arquivo.\nTente novamente mais tarde");
    }
    await downloadFile(response, filename);
  } catch (error) {
    throw new Error(
      "Ocorreu um erro ao exportar relatório.\nTente novamente mais tarde"
    );
  }
}
