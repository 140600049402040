const url = process.env.REACT_APP_API;

type Props = {
  cod: number;
};

export async function getBicPdf({ cod }: Props) {
  try {
    const link = `${url}/bic/exportPdf/${cod}?includeCroqui=true&includeFotos=true&includePlanta=false`;
    window.open(link, "_blank");
  } catch (error) {
    throw new Error(
      "Ocorreu um erro ao baixar BIC.\nTente novamente mais tarde"
    );
  }
}
