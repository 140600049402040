export class Colors {
  constructor(type) {
    this.type = type;

    this.colorsTestadas = [
      "rgb(174, 199, 232, 0.8)",
      "rgb(255, 127, 14, 0.8)",
      "rgb(44, 160, 44, 0.8)",
      "rgb(152, 223, 138, 0.8)",
      "rgb(214, 39, 40, 0.8)",
      "rgb(255, 152, 150, 0.8)",
      "rgb(148, 103, 189, 0.8)",
      "rgb(197, 176, 213, 0.8)",
      "rgb(140, 86, 75, 0.8)",
      "rgb(196, 156, 148, 0.8)",
      "rgb(227, 119, 194, 0.8)",
      "rgb(247, 182, 210, 0.8)",
      "rgb(127, 127, 127, 0.8)",
      "rgb(199, 199, 199, 0.8)",
      "rgb(188, 189, 34, 0.8)",
      "rgb(219, 219, 141, 0.8)",
      "rgb(23, 190, 207, 0.8)",
      "rgb(158, 218, 229, 0.8)",
    ];

    this.colorsCoberturas = [
      "rgb(177, 208, 143, 0.8)",
      "rgb(246, 236, 138, 0.8)",
      "rgb(250, 202, 157, 0.8)",
      "rgb(235, 179, 214, 0.8)",
      "rgb(174, 174, 235, 0.8)",
      "rgb(67, 176, 42, 0.8)",
      "rgb(255, 165, 0, 0.8)",
      "rgb(255, 255, 0, 0.8)",
      "rgb(255, 0, 0, 0.8)",
      "rgb(0, 115, 187, 0.8)",
      "rgb(114, 178, 137, 0.8)",
      "rgb(196, 217, 255, 0.8)",
      "rgb(252, 182, 103, 0.8)",
      "rgb(242, 114, 110, 0.8)",
      "rgb(252, 143, 110, 0.8)",
      "rgb(162, 203, 228, 0.8)",
      "rgb(223, 209, 206, 0.8)",
      "rgb(232, 76, 43, 0.8)",
      "rgb(246, 154, 8, 0.8)",
      "rgb(42, 183, 177, 0.8)",
      "rgb(240, 158, 104, 0.8)",
      "rgb(142, 155, 203, 0.8)",
      "rgb(242, 184, 177, 0.8)",
      "rgb(23, 19, 16, 0.8)",
      "rgb(91, 197, 124, 0.8)",
      "rgb(244, 192, 122, 0.8)",
      "rgb(238, 201, 156, 0.8)",
      "rgb(98, 163, 160, 0.8)",
      "rgb(96, 197, 134, 0.8)",
      "rgb(90, 34, 17, 0.8)",
    ];

    this.colorsEixo = [
      "rgb(177, 208, 143, 0.8)",
      "rgb(246, 236, 138, 0.8)",
      "rgb(250, 202, 157, 0.8)",
      "rgb(235, 179, 214, 0.8)",
      "rgb(174, 174, 235, 0.8)",
      "rgb(67, 176, 42, 0.8)",
      "rgb(255, 165, 0, 0.8)",
      "rgb(255, 255, 0, 0.8)",
      "rgb(255, 0, 0, 0.8)",
      "rgb(0, 115, 187, 0.8)",
      "rgb(114, 178, 137, 0.8)",
      "rgb(196, 217, 255, 0.8)",
      "rgb(252, 182, 103, 0.8)",
      "rgb(242, 114, 110, 0.8)",
      "rgb(252, 143, 110, 0.8)",
      "rgb(162, 203, 228, 0.8)",
      "rgb(223, 209, 206, 0.8)",
      "rgb(232, 76, 43, 0.8)",
      "rgb(246, 154, 8, 0.8)",
      "rgb(42, 183, 177, 0.8)",
      "rgb(240, 158, 104, 0.8)",
      "rgb(142, 155, 203, 0.8)",
      "rgb(242, 184, 177, 0.8)",
      "rgb(23, 19, 16, 0.8)",
      "rgb(91, 197, 124, 0.8)",
      "rgb(244, 192, 122, 0.8)",
      "rgb(238, 201, 156, 0.8)",
      "rgb(98, 163, 160, 0.8)",
      "rgb(96, 197, 134, 0.8)",
      "rgb(90, 34, 17, 0.8)",
    ];

    this.lotesVizinhos = ["rgba(160,157,164, 0.8)"];
    this.lote = [
      "rgb(90, 34, 17, 0.5)",
      "rgb(96, 197, 134, 0.5)",
      "rgb(98, 163, 160, 0.5)",
      "rgb(238, 201, 156, 0.5)",
      "rgb(244, 192, 122, 0.5)",
      "rgb(91, 197, 124, 0.5)",
      "rgb(23, 19, 16, 0.5)",
      "rgb(242, 184, 177, 0.5)",
      "rgb(142, 155, 203, 0.5)",
      "rgb(240, 158, 104, 0.5)",
      "rgb(42, 183, 177, 0.5)",
      "rgb(246, 154, 8, 0.5)",
      "rgb(232, 76, 43, 0.5)",
      "rgb(223, 209, 206, 0.5)",
      "rgb(162, 203, 228, 0.5)",
    ];
    this.quadra = ["rgba(114,178,137, 0.8)"];
    this.setor = ["rgba(196,217,255, 0.8)"];
    this.rgbaColor = "rgb(19,100,181, 0.8)";

    this.editResponse = null;
  }

  serRgbColor(color) {
    this.rgbaColor = color;
  }

  configReturn(iterador, arrayColors) {
    if (arrayColors.length === 1) {
      this.rgbaColor = arrayColors[0];
    } else {
      this.rgbaColor = arrayColors[iterador];
    }
  }

  fillColor(iterador) {
    if (this.editResponse) {
      return this.editResponse;
    }

    if (this.type === "Testada")
      this.configReturn(iterador, this.colorsTestadas);

    if (this.type === "Cobertura")
      this.configReturn(iterador, this.colorsCoberturas);

    if (this.type === "Eixo") this.configReturn(iterador, this.colorsEixo);

    if (this.type === "LotesVizinhos")
      this.configReturn(iterador, this.lotesVizinhos);

    if (this.type === "Lote") this.configReturn(iterador, this.lote);

    if (this.type === "Quadra") this.configReturn(iterador, this.quadra);

    if (this.type === "Setor") this.configReturn(iterador, this.setor);

    return this.rgbaColor;
  }

  strokeColor(iterador) {
    if (this.editResponse) {
      this.rgbaColor = this.editResponse();
      console.log(this.rgbaColor);
      return this.rgbaColor;
    }

    if (this.type === "Testada")
      this.configReturn(iterador, this.colorsTestadas);

    if (this.type === "Cobertura")
      this.configReturn(iterador, this.colorsCoberturas);

    if (this.type === "Eixo") this.configReturn(iterador, this.colorsEixo);

    if (this.type === "LotesVizinhos")
      this.configReturn(iterador, this.lotesVizinhos);

    if (this.type === "Lote") {
      this.configReturn(iterador, this.lote);
    }

    if (this.type === "Quadra") this.configReturn(iterador, this.quadra);

    if (this.type === "Setor") this.configReturn(iterador, this.setor);

    return this.rgbaColor;
  }

  editArrayColors(functionEdit, dadosLayer) {
    if (functionEdit && dadosLayer) {
      const aux = functionEdit(dadosLayer);

      this.editResponse = aux;
    }
  }
}
